import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { Contact } from './contact.model';
import { CrmStage } from './crm.lead.stage.model';
import { CrmTag } from './crm.tag.model';
import { ODOO_IDS } from './deal';
import { WRITE_DEALINFO_CFG } from './deal';
import { DriveFolder } from './drive.folder';
import { MailFollower } from './mail.followers';
import { MailActivity } from './mail.message';
import { OdooModel } from './odoo-model.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { OdooRelationship } from './odoo-relationship.model';
import { SaleOrder } from './sale-order.model';
import { Stage } from './stage.model';
import { IConnectable, TrelloCardEntry2 } from './trello-card';
import { User } from './user.model';

/**
 * crm.lead
 */
// export class Lead extends OdooModel implements IConnectable<Lead> {
export class Lead extends OdooModel {
   
    public readonly ODOO_MODEL = 'crm.lead';
    active: boolean = false // if it's a lost opportunity it gets archived (false)
    name:string = ""
    title:string = ""
    area: string = ""
    partner_id:OdooRelationship<Contact> = new OdooRelationship<Contact>()
    description:string = ""
    stage_id:OdooRelationship<CrmStage> = new OdooRelationship<CrmStage>()
    drive_link_docs:string 
    drive_link_production:string 
    drive_link_project:string 
    drive_link_pos: any
    tracking_code:string = ""
    _totalContractAmount: number = 0

    expected_revenue: number = 0
    probability: number = 0
    write_uid: OdooRelationship<User> = new OdooRelationship<User>()
    activity_ids: OdooMultiRelationship<MailActivity> = new OdooMultiRelationship(MailActivity)
    write_date: string = ""
    _isOverdue: boolean = false
  

    message_follower_ids: OdooMultiRelationship<MailFollower> = new OdooMultiRelationship(MailFollower)
    contact_name: string = ""
    type:string = ""
    partner_name: string = ""


    city:string = ""
    street:string = ""
    user_id :OdooRelationship<User> = new OdooRelationship<User>()
    // lng: any
    // lat: any
    
    // cordinates: string = "" // lat,lng
    tag_ids: OdooMultiRelationship<CrmTag> = new OdooMultiRelationship<CrmTag>(CrmTag)

    order_ids: OdooMultiRelationship<SaleOrder> = new OdooMultiRelationship(SaleOrder)
    trello_ids: OdooMultiRelationship<TrelloCardEntry2> = new OdooMultiRelationship(TrelloCardEntry2)
    trello_card_ids: OdooMultiRelationship<TrelloCardEntry2> = new OdooMultiRelationship(TrelloCardEntry2)
    drive_ids: OdooMultiRelationship<DriveFolder> = new OdooMultiRelationship(DriveFolder)
    drive_folder_ids: OdooMultiRelationship<DriveFolder> = new OdooMultiRelationship(DriveFolder)
    
    calendar_event_count: Number = 0
    create_date: string = "";
    create_uid: OdooRelationship = new OdooRelationship()

    
    // write_date:string = "" 

    getTitle() {
        return this.partner_id.name  + " - " + this.city + " - " + this.name + " - " + this.id  
    }

    create() {
        return new Lead()
    }


    static async _createdrive(lead: Lead, odooEM: OdooEntityManager, posaStart:string, posaDays:number) {
        try {
            //in the update code i update lead stage then launch this code, so when i pass the lead it still has the old stage
            let oldStage = lead.stage_id?.name
            let id = Number(lead.id)
            // this is convenient so i can get old and new stage without checking messages!
            var refetchedLead: Lead = (await odooEM.search<Lead>(new Lead(), [['id', '=', id]]).toPromise())[0];
            console.log("refetchedLead", refetchedLead)

            // Format current date as dd-mm-yyyy and posa start date as dd-mm-yyyy
            const currentDate = new Date();
            const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
            const formattedPosaStartDate = posaStart ? `${posaStart.split('-')[2]}-${posaStart.split('-')[1]}-${posaStart.split('-')[0]}` : '';
       
            // Prepare the row data
            const rowData = [
                [
                    formattedDate || '', // Date
                    refetchedLead.area || '', // Area
                    refetchedLead.tracking_code || '', // Tracking Code
                    refetchedLead.partner_id?.name || '', // Partner Name
                    refetchedLead.name || '', // Deal Name
                    refetchedLead.expected_revenue || '', // Expected Revenue
                    refetchedLead.user_id.name || '', // Salesperson
                    oldStage || '', // Old Stage
                    refetchedLead.stage_id?.name || '', // New Stage
                    formattedPosaStartDate || '', // POSA Start Date
                    posaDays || '', // POSA Days
                ]
            ];
    
            console.log("Writing to sheet:", {
                spreadsheetId: WRITE_DEALINFO_CFG.template_sheet_id,
                data: rowData
            });

            // //sign in
            // return new Promise(async (resolve) => {
            //     console.log("+sign in ", gapi.auth2, gapi.auth2.getAuthInstance())
                
            //     try {
            //         var is =  gapi.auth2.getAuthInstance().isSignedIn.get()
            //         if (!is)
            //             gapi.auth2.getAuthInstance().signIn().then(r => {
            //                 resolve(true)
            //             })
            //         else
            //             resolve(true)
            //     } catch (error) {
            //         resolve(true)
                    
            //     }     
            // })
    
            // Append to Google Sheet using the same method as printOrder
            await gapi.client.sheets.spreadsheets.values.append(
                {
                    spreadsheetId: WRITE_DEALINFO_CFG.template_sheet_id,
                    range: 'Dati',
                    valueInputOption: 'RAW',
                }, {
                    values: rowData
                }
            );
    
            console.log("Successfully wrote to sheet");
        } catch (error) {
            console.error("Error writing to sheet:", error);
            if (error){ 
            alert("Error writing to sheet: " + error);
            }
            throw error;
        }
    }
}