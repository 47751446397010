<!-- Navbar -->
<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand">
    <span> Cerca </span>
  </a>
  <div  *ngIf="package">
    <button class="btn  " (click)="toggleMessage()"
      [ngCLass]="{'text-primary': openMessage, 'text-light': !openMessage}">
      
      <i class="fa-solid fa-lg fa-comment fa-lg text-light"></i>
    </button>
  </div>

</app-navbar>


 <app-message-widget *ngIf="openMessage" [id]="package.id" [action]="834">
    </app-message-widget>
<!-- Scanner -->
<app-scanner *ngIf="!package && !product" (onCode)="onCode($event)"></app-scanner>

<!-- Product Quantity Editor -->
<app-product-quantity-editor *ngIf="showQuantityEditor" [product]="product" (onSave)="onQuantity($event)"
  (onCancel)="showQuantityEditor = false">
</app-product-quantity-editor>


 <!-- Scrollable content area -->
 <div class="flex-grow-1 overflow-visible" *ngIf="!openMessage">
<!-- Product Section -->
<div  *ngIf="product">
  <div class="bg-light d-flex align-items-center ps-2 py-3 text-primary">
    <h1 class="mb-0">{{ product.display_name }}</h1>
  </div>

  <ng-container *ngIf="!productAvailable">
    <br>
    <h4 class="ms-2 text-muted mb-0">Nessuna disponibiltà per il prodotto selezionato</h4>
  </ng-container>

  <ng-container *ngIf="productAvailable">
    <div class="list-group bg-white mb-5">
      <ng-container *ngFor="let l of quants">
        <div class="list-group-item bg-white" *ngIf="l.location_id.name.includes('Giacenza')">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h5 class="mb-0">{{ l.location_id.name }}</h5>
            <div class="dropdown" >
              <button class="btn btn-sm btn-primary text-white" type="button" data-bs-toggle="dropdown">
                <i class="fa-solid fa-bars"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-dark dropdown-menu dropdown-menu-dark-dark dropdown-menu dropdown-menu-dark-end">
                <li>
                  <h6 class="dropdown-header">Consuma</h6>
                </li>
                <li><a class="dropdown-item"
                    (click)="onConsume(l, O_IDS.picking_type_consume_case_id, O_IDS.location_case_consume)">Case</a>
                </li>
                <li><a class="dropdown-item"
                    (click)="onConsume(l, O_IDS.picking_type_consume_massello_id, O_IDS.location_massello_consume)">Massello/pavimenti</a>
                </li>
                <li><a class="dropdown-item"
                    (click)="onConsume(l, O_IDS.picking_type_consume_montaggio_id, O_IDS.location_montaggio_consume)">Montaggio</a>
                </li>
                <li><a class="dropdown-item"
                    (click)="onConsume(l, O_IDS.picking_type_consume_manutenzioni_id, O_IDS.location_manutenzioni_consume)">Lavori
                    interni</a></li>
              </ul>
            </div>
          </div>
          <table class="table table-sm mb-0 text-muted">
            <thead>
              <tr>
                <th>Quantità</th>
                <th>Disponibile</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ l.quantity }} {{ product.uom_id.name }}
                 {{ getPieces(product, l.quantity) }}
                </td>
                <td>
                  {{ l.available_quantity }} {{ product.uom_id.name }}
                  {{ getPieces(product, l.available_quantity) }}
                </td>
              </tr>
              <tr>
                <td>{{ getDescriptiveBarcode(product, l.quantity) }}</td>
                <td>{{ getDescriptiveBarcode(product, l.available_quantity) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<!-- Package Section -->
<div  *ngIf="package">
  <div class="bg-light ps-2 pt-3 pb-2 text-primary">
    <div class="row align-items-center">
      <div class="col-8">
        <h1 class="mb-0">{{ package.name }}</h1>
      </div>
      <div class="col-4">
        <div class="d-flex flex-column align-items-end">
          <div class="dropdown me-2 mb-2">
            <button (click)="loadLocations()" class="ms-auto btn btn-dark dropdown-toggle" type="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              Stocca
            </button>
            <div class="dropdown-menu dropdown-menu-dark" style="max-height: 80vh; overflow: visible">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item" *ngFor="let g of locationGroups | keyvalue">
                  <h2 class="accordion-header">
                    <button (click)="$event.stopPropagation()" class="accordion-button" type="button"
                      data-bs-toggle="collapse" [attr.data-bs-target]="'#loc' + g.key.replaceAll(' ', '')">
                      {{ g.key }}
                    </button>
                  </h2>
                  <div id="loc{{ g.key.replaceAll(' ', '') }}" class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body p-0">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let v of g.value" (click)="moveTo2(v)">
                          {{ v.display_name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h6 class="mb-0 me-2">{{ package.location_id.name }}</h6>
        </div>
      </div>
    </div>
  </div>

  <h5 class="ms-2 text-muted mb-2 pt-1">Contenuto

  </h5>

  <div class="list-group list-group-flush bg-white mb-5">
    <div class="list-group-item bg-white" *ngFor="let q of package?.quant_ids?.values; let i = index">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h5 class="mb-0 - me-1">{{ q.product_id.name.replaceAll(", -", "") }}</h5>
        <div class="dropdown">
          <button class="btn btn-sm btn-primary text-white" type="button" data-bs-toggle="dropdown">
            <i class="fa-solid fa-bars"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-dark dropdown-menu dropdown-menu-dark-end">
            <li>
              <h5 class="dropdown-header text-center">       Consuma</h5>
            </li>
            <li><a class="dropdown-item"
                (click)="onConsume(q, O_IDS.picking_type_consume_case_id, O_IDS.location_case_consume, package)">Case</a>
            </li>
            <li><a class="dropdown-item"
                (click)="onConsume(q, O_IDS.picking_type_consume_massello_id, O_IDS.location_massello_consume, package)">Massello/pavimenti</a>
            </li>
            <li><a class="dropdown-item"
                (click)="onConsume(q, O_IDS.picking_type_consume_montaggio_id, O_IDS.location_montaggio_consume, package)">Montaggio</a>
            </li>
            <li><a class="dropdown-item"
                (click)="onConsume(q, O_IDS.picking_type_consume_manutenzioni_id, O_IDS.location_manutenizoni_consume, package)">Lavori
                interni</a></li>
            <li>
              <h5 class="dropdown-header  text-center">    Modifica</h5>
              <li>
                <a class="dropdown-item" 
                   [class.disabled]="!isModifiableProduct(q.product_id.value)" 
                   [style.text-decoration]="!isModifiableProduct(q.product_id.value) ? 'line-through' : 'none'"
                   (click)="isModifiableProduct(q.product_id.value) && onModifyAttrs(q, package)">
                  Attributi
                </a>
              </li>
          </ul>
        </div>
      </div>
      <table class="table table-sm table-borderless mb-0 text-muted">
        <thead>
          <tr>
            <th>Quantità</th>
            <th>Disponibile</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ q.quantity | number : "1.0-2" : "it-IT" }} {{ q.product_uom_id.name }}
              {{ getPieces(q.product_id.value, q.quantity) }}
            </td>
            <td>
              {{ q.available_quantity | number : "1.0-2" : "it-IT" }} {{ q.product_uom_id.name }}
              {{ getPieces(q.product_id.value, q.available_quantity) }}
            </td>
          </tr>
          <tr>
            <td>{{ getDescriptive(q, q.quantity) }}</td>
            <td>{{ getDescriptive(q, q.available_quantity) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <app-modify-attributes (onDone)="onAttrsEditDone($event)" *ngIf="modifyingAttrsOfQuant"
    [quant]="modifyingAttrsOfQuant" (onCancel)="onCancel($event)"></app-modify-attributes>


  <div class="navbar-footer bg-dark fixed-bottom d-flex p-2" *ngIf="!modifyingAttrsOfQuant">
    <button class="btn btn-primary w-100 text-white" (click)="package = null; product = null">
      Scansiona ancora
    </button>
  </div>
</div>