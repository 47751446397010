import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductWithOnlyVariants } from '../models/product.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { first, firstValueFrom } from 'rxjs';
import { StockQuant } from '../models/stock-quant';
import { ODOO_IDS } from '../models/deal';
import { StockQuantPackage } from '../models/stock-quant-package';
import { IrAttachment, MailMessage } from '../models/mail.message';

@Component({
  selector: 'app-packs-photos',
  templateUrl: './packs-photos.component.html',
  styles: [`
    .dropdown-menu {
      width: auto;
      min-width: 200px;
    }
    .dropdown-menu.expanded {
      width: min(600px, calc(100vw - 100px));
    }
  `]
})

export class PacksPhotosComponent implements OnInit {
  @Input() product: ProductWithOnlyVariants;
  packages: any[] = [];
  attachments:  boolean = false;

  constructor(
    private odooEm: OdooEntityManager,
  ) {}


async ngOnInit() {

  //resolve stock quant ids
  await firstValueFrom(this.odooEm.resolve(this.product.stock_quant_ids));
  //collect all package ids form product.stock_quant_ids.values.package_id.id, if not empty and quantity > 0 and not in exclude_packs_location_ids
 let packages = this.product.stock_quant_ids.values.filter(
    (quant: StockQuant) => quant.package_id.id 
    && quant.available_quantity > 0
    && !ODOO_IDS.exclude_packs_location_ids.includes(quant.location_id.id)

  ).map((quant: StockQuant) => quant.package_id.id);


this.packages = await firstValueFrom(this.odooEm.search<StockQuantPackage>( 
  new StockQuantPackage(), 
  [['id', 'in', packages]]
));

//search inside the package this.product available quantity and store it in _productQuantity
for (let pack of this.packages) {
  let quant = this.product.stock_quant_ids.values.find(
    (quant: StockQuant) => quant.package_id.id === pack.id
  );
  pack._productQuantity = quant ? quant.available_quantity.toFixed(4) : 0;
}

console.log("EEEEEEEEE, ", this.packages);
}



async onClickPack(pack: StockQuantPackage, event: Event) {
  // Prevent the main dropdown from closing
  event.stopPropagation();
    await firstValueFrom(this.odooEm.resolve(pack.message_ids));
    if (pack.message_attachment_count > 0) {
      pack._attachments = await firstValueFrom(
        this.odooEm.search<IrAttachment>(
          new IrAttachment(), 
          [
            ['res_id', '=', pack.id], 
            ['res_model', '=', 'stock.quant.package']
          ]
        )
      );
    this.attachments = true
    }
    
    console.log("EEEEEEEEE, ", pack);  
    
  }
}
