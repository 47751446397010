import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { Contact } from './contact.model';
import { Partner } from './partner';
import { User } from './user.model';


export class IrAttachment extends OdooModel implements OdooSerializableInterface<MailMessageReaction> {

  public readonly ODOO_MODEL = 'ir.attachment'
  
  name: string = ""
  mimetype: string = ""
  url: string = ""
  type: 'url' | 'binary' | '' = ""
  public: boolean = false
  local_url: string = ""
  image_width: number = 0
  image_src: string = ""  // This contains the full path like "/web/image/1813/..."
  image_height: number = 0
  file_size: number = 0
  description: string = ""
  access_token: string = ""


  
  create() :IrAttachment{
    return new IrAttachment();
  }
}

export class MailActivityType extends OdooModel implements OdooSerializableInterface<MailActivity> {
  
  public readonly ODOO_MODEL = 'mail.activity.type'
  name: string = ""
  
  create() {
    return new MailActivityType();
  }
  
}

export class MailActivity extends OdooModel implements OdooSerializableInterface<MailActivity> {
  
  public readonly ODOO_MODEL = 'mail.activity'
  date_deadline: string = ""
  activity_type_id: OdooRelationship<MailActivityType> = new OdooRelationship<MailActivityType>()
  res_model_id = 0;
  res_model : string = ""
  res_id = 0;
  res_name: string = ""
  display_name: string = ""
  note: string = ""
  summary: string = ""
  state : 'overdue' | 'today' | 'planned' | '' = ''
  user_id: OdooRelationship<User> = new OdooRelationship<User>()
  create_uid: OdooRelationship<User> = new OdooRelationship<User>()
  _ga_description: string = "" //to show summary in the calendar
  _ga_linkToObject: string = "" //to link to the object in the calendar
  
  create() {
    return new MailActivity();
  }
  
}

export class MailMessageReaction extends OdooModel implements OdooSerializableInterface<MailMessageReaction> {
  public readonly ODOO_MODEL = 'mail.message.reaction';

  content : string = ""
  partner_id:OdooRelationship<Contact> = new OdooRelationship<Contact>()

  constructor( id?: number, content?: string, partner_ids?: OdooMultiRelationship<Contact>) {
    super(id);

  }

  create(): MailMessageReaction {
    return new MailMessageReaction();
  }
}


export class MailMessage extends OdooModel implements OdooSerializableInterface<MailMessage> {
  public readonly ODOO_MODEL = 'mail.message';

  res_id: number = 0
  body: string = ""
  model: string = ""
  date : string = ""
  message_type: ""|"email" | "comment" | "notification" | "user_notification" | "sms" | "snailmail" = ""
  attachment_ids: OdooMultiRelationship<IrAttachment> = new OdooMultiRelationship<IrAttachment>(IrAttachment);
  reaction_ids: OdooMultiRelationship<MailMessageReaction> = new OdooMultiRelationship<MailMessageReaction>(MailMessageReaction)
  author_id: OdooRelationship<Partner> = new OdooRelationship<Partner>()
  display_name: string = ""
  preview: string = ""
  partner_ids: OdooMultiRelationship<Partner> = new OdooMultiRelationship<Partner>(Partner)
  subtype_id: OdooRelationship<MailMessageSubtype> = new OdooRelationship<MailMessageSubtype>()
  tracking_value_ids: OdooMultiRelationship<MailTracking> = new OdooMultiRelationship<MailTracking>(MailTracking)

  create(): MailMessage {
    return new MailMessage();
  }
}

export class MailMessageSubtype extends OdooModel implements OdooSerializableInterface<MailMessageSubtype> {
  public readonly ODOO_MODEL = 'mail.message.subtype';

  name: string = ""
  res_model: string = ""
  create_date: string = ""
  create_uid: number = 0
  description: string = ""
  display_name: string = ""
  relation_field: string = ""
  // parent_id: OdooRelationship<MailMessageSubtype> = new OdooRelationship<MailMessageSubtype>()

  create(): MailMessageSubtype {
    return new MailMessageSubtype();
  }

}

export class MailTracking extends OdooModel implements OdooSerializableInterface<MailTracking> {
  public readonly ODOO_MODEL = 'mail.tracking.value';
  
  // __last_update: string = "";
  create_date: string = "";
  create_uid: number = 0;
  currency_id: OdooRelationship<Currency> = new OdooRelationship<Currency>();  
  display_name: string = "";
  field: OdooRelationship<IrModelFields> = new OdooRelationship<IrModelFields>();  
  field_desc: string = "";
  field_groups: string = "";
  field_type: string = "";
  id: number = 0;
  mail_message_id: OdooRelationship<MailMessage> = new OdooRelationship<MailMessage>();
  new_value_char: string = "";
  new_value_datetime: string = "";
  new_value_float: number = 0;
  new_value_integer: number = 0;
  new_value_monetary: number = 0;
  new_value_text: string = "";
  old_value_char: string = "";
  old_value_datetime: string = "";
  old_value_float: number = 0;
  old_value_integer: number = 0;
  old_value_monetary: number = 0;
  old_value_text: string = "";
  tracking_sequence: number = 0;
  write_date: string = "";

  create(): MailTracking {
    return new MailTracking();
  }
}

export class IrModelFields extends OdooModel implements OdooSerializableInterface<IrModelFields> {
  public readonly ODOO_MODEL = 'ir.model.fields';
  
  name: string = "";
  model: string = "";
  field_description: string = "";
  ttype: string = "";
  
  create(): IrModelFields {
    return new IrModelFields();
  }
}


  export class Currency extends OdooModel implements OdooSerializableInterface<Currency> {
    public readonly ODOO_MODEL = 'res.currency';
    
    name: string = "";
    symbol: string = "";
    
    create(): Currency {
      return new Currency();
    }
  }






