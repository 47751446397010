<app-navbar [loading]="loading" backroute=".." [leadId]="lead?.id" class="sticky-top">
    <a class="navbar-brand">
        <span>&nbsp;<b>Commesse / {{lead?.tracking_code}} - {{lead?.partner_id.name}}</b></span>
    </a>

    <div class="ms-auto dropdown">
        <button class="btn btn-link text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-bars text-white"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" target="_blank"
                    href="//o3.galimberti.eu/web#id={{lead?.id}}&cids=1&menu_id=331&action=504&model=crm.lead&view_type=form">Apri
                    in Odoo</a></li>
            <li><a class="dropdown-item" target="_blank" (click)="delete()">Elimina</a></li>
            <li><a class="dropdown-item" target="_blank" (click)="ctrl()">Ctrl costi</a></li>
        </ul>
    </div>
</app-navbar>

<!-- Stage Navigation and print message-->
<div>
    <nav class="bg-light border-bottom p-3">
        <div class="d-flex align-items-center justify-content-between">
            <!-- Stages on the left -->
            <div class="d-flex align-items-center gap-3">
                <ng-container *ngFor="let s of stages; let i = index">
                    <div class="d-flex align-items-center">
                        <a class="text-decoration-none" (click)="toStatus(s)" 
                           [class.text-primary]="s.id == lead?.stage_id?.id"
                           [class.text-muted]="s.id != lead?.stage_id?.id">
                            {{s.name}}
                        </a>
                        <i class="fa-solid fa-chevron-right ms-3 text-muted" *ngIf="i < stages.length - 1"></i>
                    </div>
                </ng-container>
            </div>
            
            <!-- Control message on the right -->
            <div *ngIf="ctrlMessage !== ''" class="text-primary">
                {{ctrlMessage}}
            </div>
        </div>
    </nav>
</div>


<div class="container-fluid p-4 h-100">
    <div class="row g-4 h-100">

        <!-- Left Column --> 
        <div class="col-md-4 h-100" style="max-width: 35%;" *ngIf="canShowLeft">
            <div class="card shadow-sm mb-4 h-100">
                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Informazioni e attività</h5>
                </div>

                <!-- Tab Navigation -->
                <ul class="nav nav-tabs px-3 pt-2">
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="activeTab === 'info'" (click)="activeTab = 'info'">
                            Informazioni
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [class.active]="activeTab === 'activities'"
                            (click)="activeTab = 'activities'">
                            Attività
                        </a>
                    </li>
                    
                </ul>

                <div class="card-body me-2 p-0">
                    <!-- Information Tab Content -->
                    <div *ngIf="activeTab === 'info'" class="p-2">
                        <!-- Title -->
                        <div class="mb-3 mt-2">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                            <ng-container *ngIf="getStage().sequence > 0">
                                <h5 class="mb-2 align-items-center">{{lead.tracking_code}} - {{lead.name}}</h5>
                            </ng-container>
                            <input *ngIf="getStage().sequence == 0" class="form-control form-control-lg"
                                placeholder="*Inserisci descrizione commessa*" [(ngModel)]="lead.name"
                                (change)="updateDealToOdoo('name')">
                             
                                <button class="btn btn-link p-0" 
                                type="button" 
                                title="Copia info per mail"
                                (click)="copyLeadData()">
                            <i [class]="copied ? 'fa-solid fa-envelope-circle-check text-primary' : 'fa-solid fa-envelope'"></i>
                        </button>
                                
                                      </div>
                        </div>

                        <!-- Contact Information -->
                        <div class="mb-3">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <label class="form-label text-muted mb-0">Cliente</label>
                                <div class="dropdown">
                                    <button class="btn btn-link  p-0" type="button" title="Modifica cliente"
                                        data-bs-toggle="dropdown">
                                        <i class="fa-solid fa-user-pen"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right"
                                        style="min-width: 300px;z-index:9999999">
                                        <form class="px-3" style="min-width: 440px;">
                                            <app-contact [mode]="'embedded'" class="embedded"
                                                (onSelect)="onContact($event)">
                                            </app-contact>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <h5 class="ms-3 mb-0">{{lead.partner_id.name}}</h5>
                        </div>
                        <div class="border-bottom mb-2"></div>

                        <!-- Three Column Layout for Area and Responsabile, and expected revenue -->
                        <div class="row mb-3">
                            <!-- Area Column -->
                            <div class="col-md-4">
                                <div class="dropdown">
                                    <label class="form-label text-muted mb-2">Area</label>
                                    <div class="d-block ms-3">
                                        <div class="badge mt-2" [ngClass]="getAreaBadgeClass(lead.area)" role="button"
                                            data-bs-toggle="dropdown" aria-expanded="false" style="cursor: pointer;">
                                            {{lead.area}}
                                            <i class="fa-solid fa-chevron-down ms-1"></i>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li
                                                *ngFor="let area of ['Tetti', 'Case', 'Facciate e Decking', 'Aziendale', 'Pavimenti', 'Massello']">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    (click)="assignArea(area)" [class.active]="area === lead.area">
                                                    {{area}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- Responsabile Column -->
                            <div class="col-md-4">
                                <div class="dropdown">
                                    <label class="form-label text-muted mb-2">Responsabile</label>
                                    <h6>
                                        <div class="d-block ms-3">
                                            <div class="badge bg-primary mt-2" role="button" data-bs-toggle="dropdown"
                                                aria-expanded="false" style="cursor: pointer;">
                                                {{lead.user_id.name}}
                                                <i class="fa-solid fa-chevron-down ms-1"></i>
                                            </div>
                                            <ul class="dropdown-menu" style="max-height: 200px; overflow-y: auto;">
                                                <li *ngFor="let user of users">
                                                    <a class="dropdown-item d-flex align-items-center"
                                                        (click)="assignUser(user)"
                                                        [class.active]="user.id === lead.user_id.id">
                                                        {{user.name}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </h6>
                                </div>
                            </div>

                            <!-- Expected Revenue Column -->
<div class="col-md-4">
    <label class="form-label text-muted mb-2">Valore atteso €</label>
    <div class="d-flex align-items-center ms-3">
        <button class="btn btn-link text-primary p-1 me-2" 
                type="button" 
                (click)="SyncExpectedRevenue()" 
                title="Sincronizza con contratti"
                data-bs-toggle="dropdown">
                <i class="fa-solid fa-rotate"></i>        </button>
        <input type="number" 
               class="form-control border-0"
               [ngModel]="lead.expected_revenue" 
               (ngModelChange)="tempRevenue = $event"
               (blur)="updateExpectedRevenue(tempRevenue)"
               [ngModelOptions]="{updateOn: 'blur'}" 
               placeholder="0">
    </div>
</div>
                    
                        </div>

                        <!-- Followers Section -->
                        <div class="row mb-3">
                            <div class="col-12">
                                <label class="form-label text-muted mb-2">Segue la commessa:</label>
                                <div class="d-flex flex-wrap gap-2 align-items-center ms-3">
                                    <div *ngFor="let follower of lead.message_follower_ids.values"
                                        class="badge bg-light text-dark d-flex align-items-center">
                                        {{follower.display_name}}
                                        <button class="btn btn-link btn-sm p-0 ms-2" (click)="removeFollower(follower)"
                                            title="Rimuovi follower">
                                            <i class="fa-solid fa-times text-dark"></i>
                                        </button>
                                    </div>

                                    <div class="dropdown">
                                        <button class="btn btn-link btn-sm text-primary" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                        <ul class="dropdown-menu" style="max-height: 200px; overflow-y: auto;">
                                            <li *ngFor="let possibleFollower of followers">
                                                <a class="dropdown-item d-flex align-items-center"
                                                    [class.active]="isFollower(possibleFollower)"
                                                    (click)="addFollower(possibleFollower)">
                                                    {{possibleFollower.name}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bottom mb-2"></div>

                        <!-- Address -->
                        <div class="mb-3 me-3">
                            <label class="form-label text-muted mb-2 me-2">Indirizzo cantiere</label>
                            <input #placesRef="ngx-places" ngx-google-places-autocomplete
                                class="form-control ms-3 border-0" [(ngModel)]="lead.street"
                                (onAddressChange)="onAddressChange($event)">
                        </div>

                        <!-- Three Column Layout for Delivery Type and Installation Data -->
                        <div class="row mb-3 d-flex">
                            <!-- Delivery Type Column -->
                            <div class="col-md-6">
                                <label class="form-label text-muted mb-2">Modalità fornitura</label>
                                <div class="d-flex gap-2 ms-3">
                                    <button class="btn text-nowrap" (click)="toggleTags('Fornitura')"
                                        [class.btn-muted]="hasTag('Fornitura')"
                                        [class.btn-outline-muted]="!hasTag('Fornitura')">
                                        Fornitura
                                    </button>
                                    <button class="btn text-nowrap" (click)="toggleTags('Fornitura e posa')"
                                        [class.btn-muted]="hasTag('Fornitura e posa')"
                                        [class.btn-outline-muted]="!hasTag('Fornitura e posa')">
                                        Fornitura e posa
                                    </button>
                                </div>
                            </div>

                            <!-- Installation Start Column -->
                            <div class="col-md-3" *ngIf="hasTag('Fornitura e posa')">
                                <label class="form-label text-muted mb-2">Inizio Posa</label>
                                <div>
                                    <input type="date" class="form-control border-0"
                                        [ngModel]="posaStartActivity?.date_deadline"
                                        (ngModelChange)="updatePosa($event, posaDuration)">
                                </div>
                            </div>

                            <!-- Installation Duration Column -->
                            <div class="col-md-3" *ngIf="hasTag('Fornitura e posa')">
                                <label class="form-label text-muted mb-2">Giorni posa</label>
                                <div>
                                    <input type="number" class="form-control border-0" [ngModel]="posaDuration"
                                        (ngModelChange)="tempDuration = $event"
                                        (blur)="updatePosa(posaStartActivity.date_deadline, tempDuration)"
                                        placeholder="0" [ngModelOptions]="{updateOn: 'blur'}">
                                </div>
                            </div>
                        </div>
                        <!-- Certification tab -->
                         
                         <div class="col-md-6">
                            <label class="form-label text-muted mb-2">Certificazioni</label>
                            <div class="d-flex gap-2 ms-3">
                                <button class="btn text-nowrap" (click)="toggleTags('No certificazione')"
                                    [class.btn-muted]="hasTag('No certificazione')"
                                    [class.btn-outline-muted]="!hasTag('No certificazione')">
                                    No certificazione
                                </button>
                                <button class="btn text-nowrap" (click)="toggleTags('100% PEFC')"
                                    [class.btn-muted]="hasTag('100% PEFC')"
                                    [class.btn-outline-muted]="!hasTag('100% PEFC')">
                                    100% PEFC
                                </button>                               
                                <button class="btn text-nowrap" (click)="toggleTags('Massiccio strutturale')"
                                    [class.btn-muted]="hasTag('Massiccio strutturale')"
                                    [class.btn-outline-muted]="!hasTag('Massiccio strutturale')">
                                    Massiccio strutturale
                                </button>

                            </div>
                        </div>
                    </div>

                    <!-- Activities Tab Content this is simply hidden so i can load it only once at the beginning -->
                    <div *ngIf="activeTab == 'activities'" class="h-100 ">
                        <app-message-widget [id]="lead.id" [action]="819">
                        </app-message-widget>
                    </div>
                </div> <!-- card body-->
 
 
 
            </div>
        </div>

        <!-- Right Column -->
        <div class="col-md-8" 
        style="max-width: 65%; height: calc(100vh - 160px); overflow-y: auto;" 
        *ngIf="canShowRight">
            <!-- Drive & Trello -->
            <div class="card shadow-sm mb-4" *ngIf="shouldShowSection('driveAndTrello')">

                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Drive & Trello</h5>
                </div>

                <div class="card-body p-0">
                    <div class="d-flex border-bottom">
                        @if(lead.name && lead.street && lead.partner_id.name) {
                        <!-- Preventivo -->
                        <div class="d-flex flex-column align-items-center border-end py-3 flex-fill">
                            <app-connect-to type="Preventivo" [deal]="lead" [driveSource]="cfg?.src"
                                [driveTarget]="cfg?.dst" [driveFolders]="filterDriveFolders('Preventivo')"
                                [trelloCards]="filterTrelloCards('Preventivo')"
                                [trelloListId]="cfg?.preventivo_trello_list"
                                [trelloBoardId]="cfg?.preventivo_trello_board" [connectable]="lead" [pos]="'top'"
                                (onConnect)="loadDeal()">
                            </app-connect-to>
                            <small class="mt-2">Preventivo</small>
                        </div>

                        <!-- Progetto -->
                        <div class="d-flex flex-column align-items-center border-end py-3 flex-fill">
                            <app-connect-to type="Progetto" [deal]="lead" [connectable]="lead"
                                [driveSource]="cfg?.project_src" driveIsMerge="true"
                                [driveTarget]="filterDriveFolders('Preventivo')[0]?.value"
                                [driveFolders]="filterDriveFolders('Progetto')"
                                [trelloCards]="filterTrelloCards('Progetto')" [trelloListId]="cfg?.project_trello_list"
                                [trelloBoardId]="cfg?.project_trello_board" [trelloTemplateCard]="cfg?.project_trello"
                                [skipAttachments]="true" [pos]="'bottom'" (onConnect)="loadDeal()"
                                (loading)="loading = true">
                            </app-connect-to>
                            <small class="mt-2">Progetto</small>
                        </div>

                        

                        <!-- POS -->
                        <div class="d-flex flex-column align-items-center py-3 flex-fill">
                            <app-connect-to type="POS" [deal]="lead" [driveSource]="cfg?.pos_src"
                                [driveTarget]="cfg?.pos_dst" [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                [driveLinkTitle]="cfg.pos_link_name" [driveFolders]="filterDriveFolders('POS')"
                                [trelloCards]="filterTrelloCards('POS')" [trelloListId]="cfg?.pos_trello_list"
                                [trelloBoardId]="cfg?.pos_trello_board" [trelloTemplateCard]="cfg?.pos_template_card"
                                [connectable]="lead" (onConnect)="loadDeal()" (loading)="loading = true">
                            </app-connect-to>
                            <small class="mt-2">POS</small>
                        </div>
                    }
                    @else {
                        <div class="d-flex flex-column align-items-center py-3 flex-fill">
                            <small class="text-muted text-center">Inserisci indirizzo e descrizione commessa per
                                creare Drive e Trello</small>
                        </div>
                    }

                    </div>
                </div>
            </div>


            <!-- Preventivi -->
            <div class="card shadow-sm mb-4" *ngIf="shouldShowSection('preventivi')">
                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Preventivi su lista</h5>
                    <button class="btn btn-link ms-auto " title="Espandi" (click)="toggleResolvePart(offersPart)"
                        [disabled]="offersPart?.sale_order_ids.ids.length == 0">
                        <i class="fa-solid fa-arrows-up-down fa-lg"></i>
                    </button>

                    <!-- nuovo preventivo -->
                    <div class="dropdown">
                        <button class="btn btn-link text-white" type="button" (click)="attachNewOffer()"
                            title="Aggiungi nuovo preventivo"
                            [disabled]="offersPart && !offersPart?.sale_order_ids.values && !offersPart?.sale_order_ids.ids.length == 0 ">
                            <i class="fa fa-plus text-primary fa-lg"></i>
                        </button>
                    </div>
                </div>

                <!-- righe preventivi -->

                <div *ngIf="offersPart && offersPart?.sale_order_ids.values" class="border" cdkDropList
                    id='part-{{offersPart.id}}' [cdkDropListConnectedTo]="partIds" [cdkDropListData]="offersPart"
                    (cdkDropListDropped)="drop($event)">


                    <div *ngFor="let s of offersPart?.sale_order_ids.values" cdkDrag [cdkDragData]="s">

                        <div class="border-bottom d-flex flex-row align-items-center py-2">
                            <i class="fa fa-bars fa-large mx-3"></i>



                            <a [routerLink]="['/leads', lead.id , 'sale' , s.id]" queryParamsHandling="preserve"
                                class="ps-3 text-nowrap">
                                <p class="mb-0 pb-0">{{s.name}}</p>
                            </a>

                            <input class="ms-3 flex-fill text-muted form-control bg-transparent border-0 px-1"
                                [ngModel]="s.ga_title" (ngModelChange)="renameSale(s, $event)"
                                [ngModelOptions]="{'updateOn':'blur'}">

                            <div class="ps-2 pe-3 ms-auto">
                                <div class="d-flex align-items-center justify-content-end text-nowrap"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Billing Component: too big to do here -->
            <app-billing *ngIf="shouldShowSection('billing')" [lead]="lead" (loading)="loading = $event">
            </app-billing>

            <!-- Production Section -->
            <div class="card shadow-sm mb-4" *ngIf="shouldShowSection('production')">
                <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
                    <h5 class="card-title mb-0">Produzione</h5>
                    <div class="dropdown ms-auto">
                        <button class="btn btn-link " type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" title="Importa sotto-commesse da template">
                            <i class="fa fa-file-import fa-lg"></i>
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngFor="let i of importableLeads">
                                <a class="dropdown-item" target="_blank" (click)="importLead(i)">{{i.name}}</a>
                            </li>
                        </ul>
                    </div>

                    <button class="btn btn-link " title="Espandi" (click)="expandAll()" [disabled]="parts.length==0">
                        <i class="fa-solid fa-arrows-up-down fa-lg"></i>
                    </button>

                    <!-- nuovo gruppo -->
                    <div class="dropdown">
                        <button class="btn btn-link text-white" type="button" (click)="attachNewPart()"
                            title="Aggiungi sotto-commessa">
                            <i class="fa fa-plus text-primary fa-lg"></i>
                        </button>
                    </div>

                </div>

                <div class="d-flex flex-column">

                    <div *ngFor="let p of parts | sortBy:'name'" class="border" cdkDropList id='part-{{p.id}}'
                        [cdkDropListConnectedTo]="partIds" [cdkDropListData]="p" (cdkDropListDropped)="drop($event)">
                        <div class="border-bottom bg-light  d-flex flex-row align-items-center py-2">
                            <!-- <i class="fa fa-bars fa-large ms-3" cdkDragHandle ></i> -->

                            <i *ngIf="!p.sale_order_ids.values" class="px-3 fa-solid fa-caret-right"
                                title="Espandi sotto-commessa" (click)="toggleResolvePart(p)"></i>
                            <i *ngIf="p.sale_order_ids.values" class="px-3 fa-solid fa-caret-down"
                                title="Comprimi sotto-commessa" (click)="toggleResolvePart(p)"></i>

                            <input class="form-control bg-transparent border-0 " [ngModel]="p.name"
                                placeholder="*Inserisci nome sotto-commessa*" [ngModelOptions]="{'updateOn':'blur'}"
                                (ngModelChange)="updatePart(p, 'name', $event)">


                            <button class="btn btn-link text-white me-1" type="button" (click)="attachNewSale(p)"
                                title="Nuovo ordine">
                                <i class="fa fa-plus text-primary"></i>
                            </button>
                            <button class="btn btn-link text-white me-1" type="button" (click)="deletePart(p)"
                                title="Rimuovi sotto-commessa">
                                <i class="fa-solid fa-trash text-primary"></i>
                            </button>
                        </div>

                        <ng-container *ngIf="p.sale_order_ids.values">

                            <div *ngFor="let s of p.sale_order_ids.values  | sortBy:'ga_order'" cdkDrag
                                [cdkDragData]="s">

                                <div class="border-bottom d-flex flex-row align-items-center py-2 ">

                                    <i class="fa fa-bars fa-large mx-3"></i>

                                    <a [routerLink]="['/leads', lead.id , 'sale' , s.id]" queryParamsHandling="preserve"
                                        class=" text-nowrap pe-2" target="_blank">
                                        <p class="mb-0 pb-0">{{s.name}}</p>
                                    </a>

                                    <!-- function could be used to get the output string and colours insted of here                                          -->

                                    <span title="Confermato" class="badge bg-primary "
                                        *ngIf="s._delivery_state =='Confermato'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Spedito interamente" class="badge  bg-success "
                                        *ngIf="s._delivery_state =='Spedito interamente'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Confermato - acquisti arrivati" class="badge  bg-primary "
                                        *ngIf="s._delivery_state =='Confermato - acquisti arrivati'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Preparato - non spedito" class="badge  bg-warning "
                                        *ngIf="s._delivery_state =='Preparato - non spedito'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Spedito parzialmente" class="badge  bg-warning "
                                        *ngIf="s._delivery_state =='Spedito parzialmente'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Bozza" class="badge bg-dark "
                                        *ngIf="s._delivery_state =='Bozza'">{{s._delivery_state}}&nbsp;</span>
                                    <span title="Annullato" class="badge bg-light text-dark"
                                        *ngIf="s._delivery_state =='Annullato'">{{s._delivery_state}}&nbsp;</span>



                                    <input class="ms-3 flex-fill text-muted form-control bg-transparent border-0 px-1"
                                        [ngModel]="s.ga_title" (ngModelChange)="renameSale(s, $event)"
                                        placeholder="*Inserisci descrizione ordine*"
                                        [ngModelOptions]="{'updateOn':'blur'}">

                                    <div class="ps-2 pe-3 ms-auto">

                                        <!-- <span *ngIf="isFirstSale(s)" class="text-danger">PRIMANOTA</span><br> -->
                                        <div class="d-flex align-items-center justify-content-end text-nowrap">


                                            <app-activity-scheduler
                                                *ngIf="(s.state == 'sale' && s.delivery_status != 'full' && s.delivery_status)"
                                                class="mb-2" [sale]="s"
                                                (loading)="loading = $event"></app-activity-scheduler>

                                            <span *ngIf="(s.state != 'sale')" class="text-muted mb-2 me-2">
                                                <button class="btn btn-sm bg-muted text-white" type="button"
                                                    [routerLink]="['/leads', lead.id , 'sale' , s.id]">
                                                    Conferma
                                                </button>
                                            </span>


                                            <app-connect-to class="d-block-inline" [connectable]="s" type="Produzione"
                                                [deal]="lead" [driveFolders]="filterProductionDriveFolders(s)"
                                                [driveSource]="cfg?.produzione_src" [driveTarget]="cfg?.produzione_dst"
                                                [driveLinkIn]="filterDriveFolders('Preventivo')[0]?.value"
                                                [driveLinkTitle]="cfg.produzione_link_name + ' > ' + s.name + ' ' + (s?.ga_title ? s.ga_title : '')"
                                                [driveAdditionalTitle]="' > ' + s.name + ' ' + (s?.ga_title ? s?.ga_title : '')"
                                                [trelloCards]="filterProductionTrelloFolders(s)"
                                                [trelloListId]="cfg?.produzione_trello"
                                                [trelloBoardId]="cfg?.produzione_trello_board"
                                                [trelloDateLabel]="'Spedizione'" (onConnect)="loadDeal()">
                                            </app-connect-to>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>


            <!-- here cost check when ready -->


        </div>
    </div>
</div>