import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { Lead } from 'src/app/models/crm.lead.model';
import { MailActivity } from 'src/app/models/mail.message';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { formatDate } from '@angular/common';
import { MrpProduction } from 'src/app/models/mrp-production';

@Component({
  selector: 'app-activity-scheduler',
  templateUrl: './activity-scheduler.component.html',
  styleUrls: ['./activity-scheduler.component.scss'],
  
})
export class ActivitySchedulerComponent implements OnInit, AfterViewInit, OnChanges{

  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Input() sale: SaleOrder;
  // @Input() activities: MailActivity[] | null;
  activity: MailActivity;
  date_deadline: Date;

  constructor(private odooEm:OdooEntityManager) { }
 

  ngOnInit(): void {
    if (this.sale.activity_ids.values?.length > 0) 
      this.activity = this.sale.activity_ids.values.find(x => x.activity_type_id.id == 4)
  }

  ngOnChanges(change) {
   // but bad activity due to resolve arrives later, and cant' detect change from outside,
    // so we need to check for it here
    if (this.sale.activity_ids.values?.length > 0) 
      this.activity = this.sale.activity_ids.values.find(x => x.activity_type_id.id == 4)
  }

  

  ngAfterViewInit(): void {
   
  }
  

  async rescheduleActivity( date:Date) {
    this.loading.next(true)
    // Converte la data di input (stringa) in un oggetto Date
    const inputDate = new Date(date);
    // Ottieni la data odierna e imposta le ore a 0 per confronto solo di date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset the time portion
    // Confronta i timestamp delle due date
    if (inputDate.getTime() < today.getTime()) {
      this.loading.next(false);
      // Usa Alert per mostrare il messaggio di errore
      alert('Non è possibile preparare l\'ordine prima di oggi');
      return;
    }
    this.odooEm.check(firstValueFrom(this.odooEm.update(this.activity, {date_deadline: date.toString()})))
    this.activity.date_deadline = date.toString()
    this.loading.next(false)
  }

  async unscheduleActivity(s:SaleOrder) {
    this.loading.next(true)
    this.odooEm.check(this.odooEm.call2("mail.activity","unlink",[[this.activity.id]]))
    this.activity = null
    this.loading.next(false)
  }

  async scheduleActivity(s:SaleOrder, date:Date) {
    this.loading.next(true)

    //non preparare se ci sono produzioni in draft
    // if (s.mrp_production_ids.ids.length > 0) {
    //   console.log("activity scheduler FOUND A PRODUCTION" , s)
    //   await firstValueFrom(this.odooEm.resolve(s.mrp_production_ids))
    //   const draft = s.mrp_production_ids.values.find(x => x.state == 'draft')
    //   if (draft) {
    //     this.loading.next(false)
    //     alert('Prima di preparare l\'ordine, completare la produzione in bozza')
    //     return
    //   }
    // }


    // Converte la data di input (stringa) in un oggetto Date
    const inputDate = new Date(date);
    // Ottieni la data odierna e imposta le ore a 0 per confronto solo di date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset the time portion
    // Confronta i timestamp delle due date
    if (inputDate.getTime() < today.getTime()) {
      this.loading.next(false);
      // Usa Alert per mostrare il messaggio di errore
      alert('Non è possibile preparare l\'ordine prima di oggi');
      return;
    }

    this.odooEm.create<MailActivity>(new MailActivity(), {
      res_model_id: 502,
      res_id: s.id,
      res_model: "sale.order",
      activity_type_id: 4,
      date_deadline: date.toString(),
      user_id: 2
    }).subscribe(x => {
      this.activity = x
      this.loading.next(false)
    })
  }

  
}
