import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { RestapiService } from '../shared/services/rest-api.service';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import {debounceTime,filter,first,switchMap, throwIfEmpty} from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, merge, Observable, of, ReplaySubject } from 'rxjs';
import { ToastService } from '../shared/services/toast.service';
import { ContactSearchService } from '../shared/services/contact-search.service';
import { Contact, ContactLink } from '../models/contact.model';
import { RecentCalls } from './recents';



export class VATResponse {
  name:string
  street:string
  city:string
  province:string
  zip:string
  vat:string
}

export function parseXMLVAT(r):VATResponse {
  var v = new VATResponse()
  
  const parser = new DOMParser();
  const xml = parser.parseFromString(r, "text/xml");
  
  console.log("r",r)

  let valid = (xml.querySelector("valid").textContent == 'true') ? true : false

  if (!valid)
    throw("Parse error")

  v.name = xml.querySelector("name").textContent;
  v.vat = xml.querySelector("vatNumber").textContent;
  // badly split address based on cap position
  var address = xml.querySelector("address").textContent;
  var ar = address.split('\n')
  v.street = ar[0]
  var sr = ar[1].split(" ")
  v.zip = sr[0]
  v.city = sr.slice(1,-1).join(" ")
  return v
}



@Component({
  selector: 'app-contact',
  templateUrl: './contact-search.component.html'
})
export class ContactSearchComponent implements OnInit {

  personQuery = true;
  companyQuery = true;
  showToCheck: boolean = true;
  contacts: Contact[];
  loading: boolean;
  input: any;
  recents:RecentCalls = new RecentCalls()
  inputSearch:string=""
  inputNew: string = ""
  vatNew: string = ""
  isCompanyNew:boolean = true

  @Input() mode = '';
  state: string;
  isNewEmbedded: boolean;

  @Output() onSelect:EventEmitter<any> = new EventEmitter();
  recentContacts: Contact[];
  $formChange: ReplaySubject<any> = new ReplaySubject<any>(1);
  showResults: boolean = true;
  filterCompany$:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  addressNew: string;
  alreadyExist: boolean;

  constructor(
    public restapi: RestapiService,
    private router: Router,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private odooEm: OdooEntityManager,
    private toastService: ToastService,
    private contactSearchService: ContactSearchService
  ) {
    // this.mode = route.snapshot.queryParams.mode;
  }

  ngOnInit() {
    if (this.mode == 'embedded')
      this.showResults = false
    
      this.recentContacts = this.recents.getRecents()

    // workaround to force 401 interceptor @giulio
    console.log(this.odooEm.search(new Contact(),[],1).pipe(first()).toPromise())
    const $routeChange = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd && e.url === '/contact')
    );

    var s = this.route.snapshot.queryParamMap.get('search')
    if (s) {
      this.inputSearch = s
      this.$formChange.next(s)
    } 
    
    let searchString = '';
    merge(this.filterCompany$,this.$formChange.pipe(debounceTime(400)), $routeChange).pipe(switchMap(() => {
      this.loading = true;
      this.router.navigate([], { queryParams: { search: this.inputSearch } })
      if (this.inputSearch === undefined || this.inputSearch === null) {
        searchString = '';
      } else {
        searchString = this.inputSearch;
      }
      this.updateSearchState(searchString);
      return this.getContacts(searchString);
    })).subscribe(contacts => {
      this.loading = false;
      if (!contacts) {
        this.toastService.show('Impossibile ottenere i contatti');
      }

      if (contacts)
        contacts = contacts.sort( (c) => {
          if (!c.name)
            return -1

          if(c.name.toLowerCase().indexOf(searchString?.toLowerCase()) != -1)
            return -1

          return 1;
        })
      this.contacts = contacts;
    });

    this.input = this.elementRef.nativeElement.querySelector('nav input');
    
    // be sure to not focus input when in child route
    if (this.router.url === '/contact') {
      this.input.focus();
    }

    this.elementRef.nativeElement.addEventListener('scroll', () => {
      this.input.blur();
    });
  }

  onFocusInput(focus:boolean) {

    if (this.mode != 'embedded')
      return

    if (!focus)  // hack to permit click from results, before to close
      setTimeout(x => {
        this.showResults = false
        
      },100)
    else
      this.showResults = true
  }


  // newInEmbedded() {
  //   if (confirm("Creo un contatto di nome " + this.inputSearch + " ?"))
  //     this.new(this.inputSearch)
  // }

  async new() {
    this.loading = true
    var contact = new Contact()
   
    var x = {
      name : this.inputNew,
      vat : this.vatNew,
      is_company : this.isCompanyNew,
      phone: "",
      mobile: "",
      email: "",
      street: "",
      city: "",
      zip: "",
      ga_arca: ""
    }
    
    try {
      contact = await this.odooEm.check(firstValueFrom(this.odooEm.create<Contact>(new Contact(), x))) 
      // .pipe(first()).toPromise()
      console.log("contact ", contact)
    } catch(e) {
      alert(e)
      return
    }
    if (this.mode === 'embedded') {
      this.redirectContact(contact)
    } else  {
      this.router.navigate(["contact", contact.id])
      this.loading = false
    }

  }

  onSearchChange() {
    this.onSelect.emit(null)
    this.$formChange.next(true)
  }

  getContacts(inputSearch: string): Observable<Contact[]> {
    
    if (!inputSearch) {
      return of(null);
    }
    
    let states = 'quotation';
    if (this.showToCheck) {
      states += ',to_check';
    }

    // check if criteria is email
    var re = /\S+@\S+\.\S+/;
    var m = this.inputSearch.match(re);
    
    // if (m && m.length) {
    //   var x:ReplaySubject<Contact[]> = new ReplaySubject(1)
      // var criteria:any = [['email', 'ilike', this.inputSearch]]

    var criteria:any = []
    //   this.odooEm.search<ContactLink>(new ContactLink(), criteria).subscribe(l => {
    //     console.log("LINKS", l)
    //     var pids = l.map(x => x.partner_id.id)
    //     this.odooEm.search<Contact>(new Contact(), [['id', 'in', pids]]).subscribe(r => {
    //       x.next(r)
    //     })
    //   })
      
    //   return x

    // } else {

    console.log("filter company", this.filterCompany$.value)
    
    inputSearch = inputSearch 
    var c:any = []
    var parts = inputSearch.split(" ")
    parts.forEach(p => {
      criteria = [
        '|',
        '|',
        ['display_name','ilike', p.replace(/[\s\*]/g, "%25")],
        ['vat','ilike', p.replace(/[\s\*]/g, "%25")],
        ['city','ilike', p.replace(/[\s\*]/g, "%25")]
    ]
      c = c.concat(criteria)
    })

    if (this.mode !== 'embedded' && this.filterCompany$.value == true) {
      c.unshift( ["parent_id", "!=", null] ) 
    }

    if (this.mode !== 'embedded' && !this.filterCompany$.value) {
      c.unshift( ["parent_id", "=", null] ) 
    }
    // if (this.filterAchived$.value )
    //   c.push( ["active", "=", false] ) 
    // else
    c.push( ["active", "=", true] ) 

    if( this.mode === "embedded") {
      c.unshift(['parent_id', '=', false])     
    }

    return this.odooEm.search<Contact>(
      new Contact(), c)
    // }
    // return this.odooEm.call<ContactSearch>(new ContactSearch(), 'advanced_search_partner', `search=${inputSearch}&page=1&state=[${states}]&is_company=${isCompanyQuery}`);
  }

  redirectContact(c) {
    if (this.mode == 'embedded') {
      this.inputSearch = c.name
      this.onSelect.emit(c)      
    } else {
      
      this.recents.addToRecents(c)
      this.recentContacts = this.recents.getRecents()

      if (c.parent_id && c.parent_id.id) {
        console.log("parent ", 'contact/' + c.parent_id.id)
        this.router.navigate(['contact/' + c.parent_id.id]);
      }
      else  {
        console.log("parent 2", c.parent_id)
        this.router.navigate(['contact/' + c.id]);
      }
    }
  }


 
  async onChangeVat(vat) {
    this.loading = true
    this.alreadyExist = false

    var contacts = await firstValueFrom(this.odooEm.search<Contact>(new Contact(), [['vat','=',vat]])) 
    if (contacts.length > 0) {
      this.inputNew = contacts[0].name
      this.alreadyExist = true
      return 
    }
    this.loading = false

    // // todo refactor
    // var xmlhttp = new XMLHttpRequest();
    //     xmlhttp.open('POST', 'checkvat/taxation_customs/vies/services/checkVatService', true);

    //     // build SOAP request
    //     var sr =
    //     "<s11:Envelope xmlns:s11='http://schemas.xmlsoap.org/soap/envelope/'>" +
    //       "<s11:Body>"+
    //         "<tns1:checkVat xmlns:tns1='urn:ec.europa.eu:taxud:vies:services:checkVat:types'>" +
    //           "<tns1:countryCode>IT</tns1:countryCode>" +
    //           "<tns1:vatNumber>" + vat +"</tns1:vatNumber>" +
    //         "</tns1:checkVat>" +
    //       "</s11:Body>" +
    //     "</s11:Envelope>"

    //     xmlhttp.onreadystatechange = () => {
    //       this.loading = false
    //         if (xmlhttp.readyState == 4) {
    //             if (xmlhttp.status == 200) {
    //                 var r = parseXMLVAT(xmlhttp.responseText)
    //                   // this.contact.name = r.name
    //                   // this.contact.city = r.city
    //                   // this.contact.street = r.street
    //                   // this.contact.zip = r.postalcode
    //                   console.log("rr",r)
    //                   this.inputNew = r.name
    //                   this.addressNew = r.street + " " + r.city

    //             }
    //         }
    //     }
    //     // Send the POST request
    //     xmlhttp.setRequestHeader('Content-Type', 'text/xml');
    //     xmlhttp.send(sr);
  }


  resetInput() {
    this.input.value = '';
  }

  updateSearchState(newString: string) {
    this.contactSearchService.searchString = newString;
  }
}
