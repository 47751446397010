<app-navbar>
    <a class="navbar-brand">
        Home
    </a>
    <app-user-info class="ms-auto"></app-user-info>
</app-navbar>

<div class="p-0">
    <!-- Anagrafiche e inventario -->
    <h5 class="mt-3 mb-2 ps-3 text-muted">Anagrafiche e inventario</h5>
    <div class="list-group">
        <a routerLink="/contact" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="me-2 fas fa-fw  fa-solid fa-building text-muted"></i>
            Contatti
        </a>
        <a routerLink="/catalog" class="list-group-item list-group-item-action rounded-0 py-3">
        <i class="fa fa-solid fa-lg fa-tag me-2"></i>
        Disponibilità e prezzi
        </a>
    </div>

    <!-- Vendite -->
    <h5 class="mt-4 mb-2 ps-3 text-muted">Vendite</h5>
    <div class="list-group">
        <a routerLink="/leads" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="me-2 text-muted fa-fw fas fa-filter"></i>
            Commesse
        </a>
        <a routerLink="/immediate-sale" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-ballot fa-lg text-muted me-2"></i>
            Vendite su lista
        </a>
        <a routerLink="/flash-sale" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-solid fa-grid-2-plus"></i>
            Scansiona e vendi
        </a>


    </div>

    <!-- Magazzino e produzione -->
    <h5 class="mt-4 mb-2 ps-3 text-muted">Magazzino e produzione</h5>
    <div class="list-group">
        <a routerLink="/pickings" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-box-check fa-lg text-muted me-2"></i>
            Movimenti di magazzino
        </a>
        <a routerLink="/search" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-barcode-scan fa-lg text-muted me-2"></i>
            Scansiona
        </a>
        <a routerLink="/productions" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-hammer-brush fa-lg text-muted me-2"></i>
            Produzioni
        </a>
    </div>

    <!-- Contabilità -->
    <h5 class="mt-4 mb-2 ps-3 text-muted">Contabilità</h5>
    <div class="list-group">
        <a routerLink="/delivery-note2" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone  fa-truck fa-lg text-muted me-2"></i>
            Documenti di trasporto
        </a>
        <a routerLink="/invoice-search" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-solid fa-file-invoice-dollar fa-lg text-muted me-2"></i>
            Fatturazione e SAL
        </a>
    </div>

    <!-- Progetti e Cantieri -->
    <h5 class="mt-4 mb-2 ps-3 text-muted">Progetti e Cantieri</h5>
    <div class="list-group">
        <a href="//c2.galimberti.eu" target="_blank" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-image fa-lg me-2"></i>Catpro
        </a>
        <a routerLink="/cantieri" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-shapes fa-lg me-2"></i>Cantieri
        </a>
        <!-- <a routerLink="/productions" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-shapes fa-lg me-2"></i>Produzioni
        </a> -->

        <a routerLink="/forklift/6" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-image fa-lg me-2"></i>Muletto 6
        </a>
    </div>
</div>

<br>
<div class="text-center"><small class="text-muted ">Aggiornamento 09.12.24</small></div>
<br>
