import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { ProductTemplateAttributeValue } from './product.template.attribute.value.model';
import { ProductTemplate } from './product.template.model';
import { ProductPackaging } from './product.packaging.model';
import { ProductSupplierinfo } from './product.supplierinfo';
import { Contact } from './contact.model';
import { MailMessage } from './mail.message';
import { Partner } from './partner';

export class MailNotification extends OdooModel implements OdooSerializableInterface<MailNotification> {
  public readonly ODOO_MODEL = 'mail.notification';

  res_partner_id:OdooRelationship<Contact> = new OdooRelationship<Contact>();
  is_read:boolean= false
  mail_message_id:OdooRelationship<MailMessage> = new OdooRelationship<MailMessage>()
  author_id:OdooRelationship<Contact> = new OdooRelationship<Contact>()
  display_name:string = ""
  failure_reason:string = ""
  failure_type:string = ""  
  id: number;
  notification_status:string = ""
  notification_type:string = ""
  read_date:string = ""
  __last_update:string = ""
  _ga_linkToObject: string = "" //to link to the object in the calendar
  _ga_description: string = "" //description of source object
  

  







 
  constructor(id?: number, name?: string, default_code?: number, codice_fornitore?: number, description_sale?: string,
              prezzo_impresa?: string, qty_available?: number,inventory_qty_needed?:number, list_price?: number, uom_id = new OdooRelationship()) {
    super(id);
    
  }

  create(): MailNotification {
    return new MailNotification();
  }
}


