
<div class="d-flex flex-nowrap" id="menu">


  <div class="input-group ">
    <button 
        type="button" 
        class="text-white btn btn-outline-primary   text-nowrap" 
        [ngClass]="{'bg-primary': activeTemplate?.id === selectedTemplate?.id}"
        (click)="toggleProductTemplate(selectedTemplate.id,selectedTemplate.name)">
        {{selectedTemplate.name}}
      </button>

    <!-- <button type="button" class="btn btn-outline-secondary">Action</button> -->
    <button type="button" class="btn  text-white btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="visually-hidden">Toggle Dropdown</span>
    </button>
   
    <ul class="dropdown-menu p-0" >
      <div class="accordion border-0" id="accordionExample" style="width: 400px;">
        <div class="accordion-item border-0" *ngFor="let t of productTemplates | keyvalue">
            <button
              (click)="$event.stopPropagation()"
              class="accordion-header link-underline-opacity-0 accordion-button collapsed text-muted"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + t.key.replaceAll(' / ','')">
              {{t.key}}
          </button>
          <!-- {{t.key.replace(' / ','')}} -->
          <div 
          
            [id]="'collapse' + t.key.replaceAll(' / ','')"
            class="accordion-collapse collapse" 
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body p-0">
              <ul class="list-group">
                <li 
                  *ngFor="let p of t.value" 
                  class="list-group-item">
                  <a 
                    (mouseup)="$event.stopPropagation(); toggleProductTemplate(p.id, p.name)"
                    type="button" class="" data-bs-toggle="dropdown" aria-expanded="false">
                    {{p.name}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ul>
    <input class="form-control border-primary" autofocus autocomplete="off" placeHolder="Cerca per nome" id="search-input" (ngModelChange)="inputSearch.next($event)" [ngModel]="inputSearch | async" name="s1">
  </div>
</div>



<!--- Search by attribut -->
<div id="inventory" class="card rounded-0" [hidden]="inventoryClosed">
  
  <div class="card-body p-0">
    <table class="table table-bordered table-hover" data-test-id="tableInventory">
      
      <thead 
        [hidden]="!activeTemplate" 
        class="sticky-to bg-white border border-dark border-bottom"
        >
        <tr>
          <th colspan="9">
            <div class="w-100 d-flex align-items-start">

            
              <!-- <ng-container *ngIf="!showAttrs">


              </ng-container> -->




                <ng-container *ngFor="let a of activeTemplate?.attribute_line_ids.values">
                  <div
                    *ngIf="a.value_ids.ids.length != 1 && !a.attribute_id.name.startsWith('Lunghezza')"
                    (mousedown)="mousedown($event)" 
                    class="flex-wrap d-flex align-items-start"
                  >
                    <!-- <ng-container *ngIf="showAttrs && ODOO_IDS.dimensionalAttributes.includes(a.attribute_id.id)">  -->
                      <div
                        class="text-nowrap w-100 me-3 mb-2 "
                        *ngIf="a.value_ids.values.length"
                      >
                        {{a.display_name}} 
                      </div>  
                    <!-- </ng-container> -->

                    <!-- @if (ODOO_IDS.dimensionalAttributes.includes(a.attribute_id.id)) {
                      <h2>Hello</h2>
                    } -->

                    <!-- <ng-container *ngIf=" a.value_ids.values.length && !showAttrs" class="p-2">
                      <input>
                    </ng-container> -->
                    
                    <ng-container *ngIf="a.value_ids.values.length" class="p-2">
                      <span
                        style="user-select: none;"
                        *ngFor="let v of a.value_ids.values | sortAsNumber" 
                        (click)="toggleCriteria({attributeLine: a, attributeValue: v});refresh$.next(true)"
                        [ngClass]="{'bg-primary': hasCriteria({attributeLine: a, attributeValue: v})}"
                        class="badge text-dark me-2" 
                        (mouseover)="mouseover($event)">
                          {{v?.name}} 
                      </span>
                    </ng-container>
                  </div>
                </ng-container>
            </div>
          </th>
        </tr>
      </thead>
      <!-- intestazione della tabella risultati-->
      <thead 
        style="top:0px"
        class="text-white sticky-top border-dark border-4 border-top-0 border-start-0 border-end-0 bg-dark"
      >

        <tr>
          <th></th>
          <th>ID</th>
          <th title="Descrizione prodotto">Prodotto</th>
          <th class="text-center" title="Pacchi e foto">Colli</th>
          <th class="text-center" title="Costo di acqusito del prodotto">Costo</th>
          <th class="text-center" title="Prezzo di vendita consigliato secondo listino standard">Vendita</th>
          <th class="text-center" title="">UdM</th>
          <th>Qtà</th>
  
          <!-- <th>Mag</th> -->
          <!-- <th>Mag</th> -->
          <th colspan="2">Qtà libera</th>
        
        </tr>
        </thead>
      
        <tbody cdkDropList 
          id="inventoryList"
          [cdkDropListConnectedTo]="saleIds"
          (cdkDropListDropped)="drop($event)"
        >
            <!-- VARIANT --> 
            <tr *ngIf="canCreateVariant() && products.length == 0">
              <td colspan="9">
                <button class="btn bg-primary" (click)="createVariant()">Crea nuova variante</button>
              </td>
            </tr>
  
            <tr
              cdkDrag
              [cdkDragDisabled]="noDrag"
              [cdkDragData]="p"
              *ngFor="let p of products"
              class="cursor-pointer text-nowrap"
              (click)="insert(p)"
            ><td class="text-wrap align-middle  text-center py-1">  
              <i *ngIf="p.reordering_min_qty > 0" class="fa-solid fa-star text-success" title="Prodotto standard a magazzino"></i>
              <i *ngIf="p.additional_product_tag_ids.ids?.includes(20)" class="fa-solid text-danger fa-trash-clock" title="Fondo di magazzino. Consumare con priorità"></i>
              <i *ngIf="!(p.reordering_min_qty > 0) && !p.additional_product_tag_ids.ids?.includes(20) && !p.free_qty " class="fa-solid text-body-secondary fa-cart-shopping " title="Prodotto fuori standard. Acqusitato su richiesta"></i>
              <i *ngIf="!(p.reordering_min_qty > 0) && !p.additional_product_tag_ids.ids?.includes(20) && p.free_qty" class="fa-solid text-warning fa-down-to-line" title="Prodotto fuori standard disponibile. Vendere con priorità"></i>
            </td>
            <td class="text-wrap align-middle py-1">
                {{p.id}}
              </td>
              <td class="text-nowrap align-middle py-1">
                {{p.display_name.replaceAll(", -","")}}
                <a class="text-wrap btn btn-link ms-1" 
                   title="Apri in Odoo" 
                   href="https://o3.galimberti.eu/web#id={{p.id}}&cids=1&menu_id=223&action=393&model=product.product&view_type=form" 
                   target="_blank"
                   (click)="$event.stopPropagation()">  <!-- Add this -->
                  <i class="text-wrap fa-solid fa-magnifying-glass my-0 py-1"></i>
                </a>     
              </td> 
              <td class="text-nowrap align-middle text-center py-1 position-relative"
              (click)="$event.stopPropagation()">
              <app-packs-photos [product]="p"></app-packs-photos>
          </td> 
              <td class="text-nowrap align-middle text-center py-1">{{p.lst_price  | number : '1.2-2':'it-IT'}} €</td>
              <td class="text-nowrap align-middle text-center py-1">{{p._lst_price ? (p._lst_price  | number : '1.2-2':'it-IT')  : '-'}} €</td>
              <td class="text-nowrap align-middle text-center py-1">{{p.uom_id.name}} </td>
              <td class="text-nowrap align-middle text-body-tertiary py-1">{{p.qty_available | number : '1.1-1':'it-IT'}}</td>
              <!-- <td>{{p.virtual_available | number : '1.0-2':'it-IT'}} {{p.uom_id.name}}</td> -->
               <!-- se consumabile, 9999+ | se riservata, mostra solo libera | se impegnata ma non riservata (free=available, periso, segati), mostra available - outgoing -->
              <td  class="text-nowrap align-middle fw-bold"> {{ getFree(p) | number : '1.1-1':'it-IT' }} </td>
              <td  class="text-nowrap align-middle fw-bold py-1">{{getInPzFast(p)}}</td>
            </tr>
        </tbody>
    </table>
  </div>
</div>
