<div class="dropdown" style="z-index: 30000">
    <button class="btn btn-link position-relative" id="dropdownMenuButton" data-bs-toggle="dropdown"
        data-bs-auto-close="false" aria-expanded="false" (click)="toggleOpen()">

        <div class="position-relative d-inline-block">

            <i class="fa-solid fa-lg fa-bell text-light"></i>

            <!-- Activities badge with opacity -->
            <span class="badge bg-primary text-white  top-0 start-0 translate-middle "
                *ngIf="activitiesLength > 0 && loaded">
                {{activitiesLength}}
            </span>

            <!-- Notifications badge with opacity -->
            <span class="badge bg-warning text-white  top-0 start-150 translate-middle "
                *ngIf="unreadNotificationsLength > 0 && loaded">
                {{unreadNotificationsLength}}
            </span>
        </div>
    </button>

    <div class="dropdown-menu dropdown-menu-end" style="width: 450px;" aria-labelledby="dropdownMenuButton">



        <div class="dropdown-header d-flex align-items-center justify-content-between border-bottom overflow-auto"
            style="z-index: 30000000; max-height: 40%">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'activities'"
                        (click)="setActiveTab('activities', $event ); ">
                        Attività <span class="badge bg-primary ms-1">{{activities.length}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'notifications'"
                        (click)="setActiveTab('notifications', $event)">
                        Notifiche <span class="badge bg-warning ms-1">{{unreadNotificationsLength}}</span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Activities Tab -->
        <div class="tab-content overflow-auto" style="z-index: 30000000;">

            <div class="tab-pane fade" [class.show]="activeTab === 'activities'"
                [class.active]="activeTab === 'activities'">

                <!-- Open clanedar -->
                <div
                    class="px-3 py-2 border-bottom d-flex justify-content-between align-items-center sticky sticky-top bg-white">
                    <button class="btn btn-link p-0" (click)="openCalendar()">
                        <small>Apri calendario</small>
                    </button>
                </div>
                <!-- Loading State -->
                <div *ngIf="loading || !activitiesloaded" class="text-center p-3">
                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="visually-hidden">Carico...</span>
                    </div>
                </div>

                <!-- Empty State -->
                <div *ngIf="!loading &&  activitiesLength===0 " class="text-center p-3">
                    <p class="text-muted mb-0">Nessuna attività in programma</p>
                </div>
                @if (activitiesloaded){

                <!-- Activities Item -->
                <div *ngFor="let activity of activities" class="py-2 px-3 border-bottom">
                    <!-- First Row: Date and Actions -->
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <span class="badge" [ngClass]="{
                                'bg-danger': activity.state === 'overdue',
                                'bg-warning': activity.state === 'today', 
                                'bg-info': activity.state === 'planned'
                                }">
                            {{activity.date_deadline | date:'dd/MM/yyyy'}}
                        </span>
                        <span class="text-muted">Creato da: {{activity.create_uid.name}}</span>

                        <div>
                            <button class="btn btn-link p-1 fs-5" (click)="openActivity(activity, $event)" title="Apri">
                                <i class="fa-solid fa-arrow-up-right-from-square text-primary"></i>
                            </button>
                            <button class="btn btn-link p-1 fs-5" (click)="deleteActivity(activity, $event)"
                                title="Elimina attività">
                                <i class="fa-solid fa-trash text-primary"></i>
                            </button>
                            <button class="btn btn-link p-1 fs-5" (click)="completeActivity(activity, $event)"
                                title="Segna come completata">
                                <i class="fa-solid fa-check text-success"></i>
                            </button>
                        </div>
                    </div>

                    <!-- Second Row: Output -->
                    <div class="text-muted mb-1" *ngIf="activity.res_model">
                        {{getOutput(activity)}}
                    </div>

                    <!-- Third Row: Display Name -->
                    <div class="fw-medium">{{activity.display_name}}</div>
                </div>
 }
            </div>
        </div>
   

        <!-- Notifications Tab -->
        <div class="tab-pane fade" style="z-index: 30000000;" [class.show]="activeTab === 'notifications'"
            [class.active]="activeTab === 'notifications'">
            <!-- Filter Toggle and Mark All Read -->
            <div
                class="px-3 py-2 border-bottom d-flex justify-content-between align-items-center sticky sticky-top bg-white">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="showUnreadOnly" [(ngModel)]="showUnreadOnly">
                    <label class="form-check-label" for="showUnreadOnly">Mostra solo non lette</label>
                </div>
                <button class="btn btn-link p-0" (click)="markAllAsRead()">
                    <small>Segna tutte come lette</small>
                </button>
            </div>

            <!-- Loading State -->
            <div *ngIf="loading || !notificationsloaded" class="text-center p-3">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">Carico...</span>
                </div>
            </div>

            <!-- Empty State -->
            <div *ngIf="!loading && getFilteredNotifications().length === 0" class="text-center p-3">
                <p class="text-muted mb-0">Nessuna notifica da leggere</p>
            </div>
            @if (notificationsloaded){

            <!-- Notifications Item -->
            <div *ngFor="let notification of getFilteredNotifications()" class="py-2 px-3 border-bottom">
                <!-- First Row: Date and Actions -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                    <span class="badge"
                        [ngClass]="{'bg-primary': !notification.is_read, 'bg-light text-dark': notification.is_read}">
                        {{notification.mail_message_id.value?.date | date:'dd/MM/yyyy'}}
                    </span>
                    <span class="text-muted">Creato da: {{notification.mail_message_id.value?.author_id.name}}</span>
                    <div>
                        <button class="btn btn-link p-1 fs-5" (click)="openNotification(notification, $event)"
                            title="Apri">
                            <i class="fa-solid fa-arrow-up-right-from-square text-primary"></i>
                        </button>
                        <button class="btn btn-link p-1 fs-5" *ngIf="!notification.is_read"
                            (click)="flagNotification(notification, $event)" title="Segna come letta">
                            <i class="fa-solid fa-check text-success"></i>
                        </button>
                        <button class="btn btn-link p-1 fs-5" *ngIf="notification.is_read"
                            (click)="flagNotification(notification, $event)" title="Segna come non letta">
                            <i class="fa-solid fa-rotate-left text-muted"></i>
                        </button>
                    </div>
                </div>

                <!-- Second Row: Output -->
                <div class="text-muted mb-1" *ngIf="notification.mail_message_id">
                    {{getOutput(notification)}}
                </div>

                <!-- Third Row: Message Body -->
                <div [innerHTML]="notification.mail_message_id.value?.body"></div>
            </div>
    }
        </div>
    </div>
</div>