import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SaleOrderLine } from '../models/sale-order-line.model';
import { StockMove } from '../models/stock-move';
import { StockPicking } from '../models/stock-picking';
import { GapiService } from '../shared/services/g-api.service';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { PRINTS_SALEORDER_CFG } from 'src/app/models/deal';
import { AccountTax } from '../models/account-tax.model';
import { Product } from '../models/product.model';
import { ProductSupplierinfo } from '../models/product.supplierinfo';
import { ProductTemplateAttributeValue } from '../models/product.template.attribute.value.model';
import { SaleOrder } from '../models/sale-order.model';
import { row2printable } from '../shared/print-util';
import { Lead } from '../models/crm.lead.model';
import { Contact } from '../models/contact.model';
import { ProductPackaging } from '../models/product.packaging.model';
import { CrmTag } from '../models/crm.tag.model';

@Component({
  selector: 'app-sale-print',
  templateUrl: './sale-print.component.html'
})

export class SalePrintComponent implements OnInit, AfterViewInit {


  id: any;
  loading: boolean;
  pickings: StockPicking[];
  moves: StockMove[];
  @Input() order: SaleOrder;
  @Input() orderLines?: SaleOrderLine[];
  @Output() onDone:EventEmitter<void> = new EventEmitter()
  
  constructor(
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private gapiService: GapiService,
    private odooEm: OdooEntityManager
  ) { }
 

  ngOnInit(): void {

   
  }

  async ngAfterViewInit(): Promise<void> {
    // this.route.params.subscribe(async params => {
      this.id = this.order.id
      // available routes in sale
      await this.load()
      this.existCondition()
    // })
  }
 

  existCondition() {

    var existCondition = setInterval(async () => {
      if (this.elementRef.nativeElement.querySelectorAll('[data-print-col]').length && this.elementRef.nativeElement.querySelectorAll('[data-print-col]').length > 0) {
        clearInterval(existCondition);
        await this.print()
      }
    }, 100); // check every 100ms
  }

 

  getDescriptive(line:SaleOrderLine) {

    console.log("DESC ", line)


    if (!line.product_id.value)return
    if (line.product_uom_qty == 0 || !line.product_id.value.packaging_ids.values)    // dont want to show 
      return 
    var ps = line.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = line.product_uom_qty
    
    var d = ""

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    console.log("XXXXXXXX1", ps)
    ps.forEach((p, i) => {

      console.log("DESC XX ", p)
      
      // if (p.sales && !p.name.includes("netti")) {
        var x = Math.floor(q / p.qty)
        var xx = Number((q / Number(p.qty.toFixed(4))))
        var isRound = xx % 1 == 0
        console.log("XXXXXXXX2 ", p.name, i, x, xx, isRound)


        if ((ps.length - 1) == i) {
          d += parseFloat((q / p.qty).toFixed(4)) + " " + p.name + " \n"
          // q = q - (xx * p.qty)
        } else if (!isRound) {
          q = q - (x * p.qty)
          d += x + " " + p.name + " \n"
        } else {
          q = q - (xx * p.qty)
          d += xx + " " + p.name + " \n"
        }
    })
    return d
  }

  getPz(line:SaleOrderLine) {

    var l = Number(this.getVariantAttribute(line, 'Lunghezza')?.name)
    var h = Number(this.getVariantAttribute(line, 'Altezza')?.name)
    var w = Number(this.getVariantAttribute(line, 'Larghezza')?.name)

    if (line.product_id.value.uom_id.name.includes('²')) 
      var v = l  * w / 1000000
    else if (line.product_id.value.uom_id.name.includes('³'))
      var v = l * h * w / 1000000000  
    

    return line.product_uom_qty / v
  }

  async load() {

    this.loading = true

    var order: SaleOrder = (await this.odooEm.search<SaleOrder>(new SaleOrder(), [["id", "=", this.id]]).toPromise())[0]
    // await this.odooEm.resolveSingle(new StockMove(), order.partner_id).toPromise()
    // await this.odooEm.resolveSingle(new StockPickingType(), order.picking_type_id).toPromise()

    await this.odooEm.resolve(order.order_line).toPromise()
    await this.odooEm.resolveSingle(new Contact(), order.partner_id).toPromise()
    
    await this.odooEm.resolveSingle(new Lead(), order.opportunity_id).toPromise()
    if (order.opportunity_id.value)
      await this.odooEm.resolve(order.opportunity_id.value.tag_ids).toPromise()
    // alert(order.opportunity_id.value.tag_ids.values[0].name)

    await this.odooEm.resolveArray(new StockMove(), order.order_line.values, "move_ids").toPromise()

    var moveids = order.order_line.values.map(l => l.move_ids.values).reduce((acc, val) => acc.concat(val), []);
    await this.odooEm.resolveArrayOfSingle(new StockPicking(), moveids, "picking_id").toPromise()
    
    // BAD FIX STAMPARE NOTE
    order.note =  order.note ? order.note.replace(/<[^>]*>?/gm, '') : ""

    this.pickings = await this.odooEm.search<StockPicking>(new StockPicking(), [["origin", "=", order.name]]).toPromise()
    this.moves = await this.odooEm.search<StockMove>(new StockMove(), [["picking_id", "in", this.pickings.map(p => p.id)]]).toPromise()
    await this.odooEm.resolveArrayOfSingle(new Product(), order.order_line.values, "product_id").toPromise()
   
    await this.odooEm.resolveArray(new AccountTax(), order.order_line.values, "tax_id").toPromise()

    var products = []
    order.order_line.values.forEach(s => {
      if (s.product_id && s.product_id.value)
        products.push(s.product_id.value)
    })


    if (products.length > 0) {

      await this.odooEm.resolveArray(new ProductPackaging(),products,"packaging_ids").toPromise()
      
      // await 
      // await 
    }

    await this.odooEm.resolveArray(new ProductSupplierinfo(), products, "variant_seller_ids").toPromise()
    await this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise()
    this.order = order

    console.log("order ", order)
    this.loading = false
  }
  

  innerText(s:string) {
    return s.replace("<p>", "").replace("</p>", "")
  }


  isLineDoubleUM(line) {
    return true
  }

  getMoves(line:SaleOrderLine) {
    // todo - getting just the last one ?
    if (!line.move_ids.values)
      return
    var next = line.move_ids.values[line.move_ids.values?.length -1]
    var moves = []
    // var next = original
    
    while(next != null) {
      moves.push(next)
      if (next.move_orig_ids.ids.length > 0) {
        var x = this.moves.find(m => next.move_orig_ids.ids.includes(m.id)) 
        next = x
      } else {
        next = null
      }
    }

    return moves.reverse()

  }

  getVariantAttribute(line: SaleOrderLine, name: string):ProductTemplateAttributeValue|null {
    
    if (!line.product_id)
      return


      if (!line.product_id.value || !line.product_id.value.product_template_attribute_value_ids)
      return 

    if (line.product_id.value.product_template_attribute_value_ids.values) {
      var f = line.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name == name
      })
    }

    return (f && f.length > 0) ? f[0] : null
  }

  isLineEditable(line: SaleOrderLine) {
    if (!line.product_id || !line.product_id.value)
      return false
    var a = line.product_id.value.product_template_attribute_value_ids.values.filter(x => x.name == "su misura")
    return a.length == 1
  }


  async print(): Promise<void> {
    
    this.loading = true;

    let headerRow; // just one

    // headerRow = ["Ordine di acquisto", "Fornitore", "Riferimento fornitore", "Valuta", "Data conferma", "Consegna a","Note","Termine di resa","Termini di pagamento" ]
    this.elementRef.nativeElement.querySelectorAll('[data-print-row-header]').forEach(element => {
      headerRow = (row2printable(element))
    });

    const headerDatas = []; // more than one
    this.elementRef.nativeElement.querySelectorAll('[data-print-row]').forEach(element => {
      headerDatas.push(row2printable(element));
    });

    var sheetid = await this.gapiService.printOrder(
      PRINTS_SALEORDER_CFG.template_sheet_id,
      PRINTS_SALEORDER_CFG.spool_folder_id,
      headerRow,
      headerDatas)
    window.open('https://docs.google.com/spreadsheets/d/' + sheetid, '_blank');
    this.loading = false;
    this.done()

  }


  done() {
    this.onDone.emit()
  }
}
