import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { OdooRelationship } from './odoo-relationship.model';
import { Partner } from './partner';

export class User extends OdooModel implements OdooSerializableInterface<User> {
  public readonly ODOO_MODEL = 'res.users';

  id: number;
  name: string;
  partner_id: OdooRelationship<Partner> = new OdooRelationship<Partner>();


  constructor(id?: number, name?: string) {
    super(id);
    this.id = id;
    this.name = name;

  }

  create(): User {
    return new User();
  }
}
