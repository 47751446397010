import { Component, EventEmitter, Input, OnInit, Output, output } from '@angular/core';
import { Contact } from '../../models/contact.model';
import { Partner } from '../../models/partner';
import { User } from '../../models/user.model';
import { MailActivity } from '../../models/mail.message';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { first, firstValueFrom } from 'rxjs';
import {MailNotification } from '../../models/mail.notification';
import { MailMessage } from '../../models/mail.message';
import { FlowService } from 'src/app/flow/services/flow.service';
import { get } from 'http';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { Lead } from 'src/app/models/crm.lead.model';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { StockPicking } from 'src/app/models/stock-picking';

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html'
})
export class CalendarWidgetComponent implements OnInit {

  activities: MailActivity[] = [];
  notifications: MailNotification[] = [];
  loading = false;
  userId: number;
  user: User;
  activeTab = 'activities'; // Default tab
  showUnreadOnly = true;
  activitiesLength: number;
  unreadNotificationsLength: number;
  @Output() messageToNavbar: EventEmitter<{activities: number, notifications: number}> = new EventEmitter();
  activitiesloaded = false;
  notificationsloaded = false;
  loaded = false; //numbers loaded
  isOpen = false;

  constructor(private odooEm: OdooEntityManager) {}

  async ngOnInit() {
      await this.loadUserInfo();
      //set an interval to loadnumbers every 30 seconds, after 5 seconds from the start
      setTimeout(async () => {
        await this.loadNumbers();
      }, 5000);
  }

  //update info every xx seconds 
  async ngAfterViewInit() {
      setInterval(async () => {  await this.loadNumbers();  }
      , 30000);
  }

  async loadNumbers() {
    this.loaded = false;
    if (!this.isOpen) {
    //fetch notifications of type "inbox"
    this.notifications = await firstValueFrom(this.odooEm.search<MailNotification>(
      new MailNotification(), 
      [['notification_type', '=', 'inbox'], ['res_partner_id', '=', this.user.partner_id.id]]
    ));
     //fetch activities with activity type id !=6   
     this.activities = await firstValueFrom(
      this.odooEm.search<MailActivity>(
        new MailActivity(), 
        [['user_id', '=', this.user.id],['activity_type_id', '!=', 6]]
      )
    );    
    this.unreadNotificationsLength = this.notifications.filter(n => !n.is_read).length;
    this.activitiesLength = this.activities.length;
    this.loaded = true;
  }
  }

  async toggleOpen() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      await this.loadDetails();
    }
  }


  async loadDetails() {
    await this.loadNumbers()
    await this.loadActivities();
    await this.loadNotifications();
  }

  // async emitDataToNavbar() {
  //   this.messageToNavbar.emit({
  //     activities: this.activitiesLength,
  //     notifications: this.unreadNotificationsLength
  //   });
  // }


  async loadUserInfo() {
    const result: any = await this.odooEm.odoorpcService.getSessionInfo();
      this.userId = result.result.user_id[0];
      //fetch all userse
      const users = await firstValueFrom(this.odooEm.search<User>(new User()));
      //find in users the one with the same id
      this.user = users.find(u => u.id === this.userId);
  }

  async loadActivities() {
    this.activitiesloaded = false;
    this.loading = true;
    try {
    //   //fetch activities with activity type id !=6   
    //   this.activities = await firstValueFrom(
    //     this.odooEm.search<MailActivity>(
    //       new MailActivity(), 
    //       [['user_id', '=', this.user.id],['activity_type_id', '!=', 6]]
    //     )
    //   );    
 
    this.loadActivitiesDescription(this.activities);
    //   // Sort activities by deadline in ascending order     
   
    this.activities.sort((a, b) => {
      return new Date(a.date_deadline).getTime() - new Date(b.date_deadline).getTime();
    });
    this.activitiesloaded = true;
      console.log('Activities:', this.activities);
      this.activitiesLength = this.activities.length;
    } catch (error) {
      console.error('Error loading activities:', error);
    } finally {
      this.loading = false;
    }
  }

  async loadNotifications() {
    this.notificationsloaded = false;
    this.loading = true;
    // //find the partner that matches with the user. this is needed cause notifications are linked to partners
    // const partner = this.user.partner_id;
    // console.log('Partner:', partner);
    
    try {
      // //fetch notifications of type "inbox"
      // this.notifications = await firstValueFrom(this.odooEm.search<MailNotification>(
      //   new MailNotification(), 
      //   [['notification_type', '=', 'inbox'], ['res_partner_id', '=', this.user.partner_id.id]]
      // ));
      
      //resolve mail message references
      await firstValueFrom(
        this.odooEm.resolveArrayOfSingle(
          new MailMessage(), 
          this.notifications, 
          'mail_message_id'
        )
      );
      this.loadNotificationDescription(this.notifications);
      this.notificationsloaded = true;
      // Sort notifications by id in descending order
    this.notifications.sort((a, b) => b.id - a.id);
      this.unreadNotificationsLength = this.notifications.filter(n => !n.is_read).length;
    } catch (error) {
      console.error('Error loading notifications:', error);
    } finally {
      this.loading = false;
    }
  }

  //accept activity or notification and return a string to show in the calendar
  getOutput(input: MailActivity | MailNotification): string {
    let output = '';
    if (input instanceof MailActivity) {
      output = this.getResModelName(input.res_model);
    } else if (input instanceof MailNotification) {
      output = this.getResModelName(input.mail_message_id?.value?.model);
    }
    if (output != 'Contatta ML!') {
      //truni descriiption after 20 chars
    output += ' '
    if (input._ga_description.length > 30) {
      output += input._ga_description.substring(0, 30) + '...';
    }
    else {
      output += input._ga_description;
    }
    }
    return output;
  }

    getResModelName(model: string): string {
    let name = "Contatta ML!"
    if (model === 'res.partner') {
      name = 'Contatto';
    } else if (model === 'sale.order') {
      name = 'Vendita';
    } else if (model === 'crm.lead') {
      name = 'Commessa';
    }
    else if (model === 'purchase.order') {
      name = 'Acquisto';
    }
    else if (model === 'stock.picking') {
      name = 'Trasferimento';
    }
    return name;
  }

  getFilteredNotifications(): MailNotification[] {
    if (!this.notifications) return [];
    
    return this.notifications
      .filter(notification => !this.showUnreadOnly || !notification.is_read);
      // No need to sort here as the array is already sorted
  }

  async loadActivitiesDescription(activities: MailActivity[]) {
      //here we add different data based on the models. for now we checked partner, sale order and crm lead using res.id which contains the id of the object
      let SalesActivities = activities.filter(a => a.res_model === 'sale.order');
      let PartnersActivities = activities.filter(a => a.res_model === 'res.partner');
      let LeadsActivities = activities.filter(a => a.res_model === 'crm.lead');

      let saleOrders: SaleOrder[] = [];
      let partners: Partner[] = [];
      let leads: Lead[] = [];
      // create arrays to search for the objects
      if(SalesActivities.length > 0) {
       saleOrders = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [['id', 'in', SalesActivities.map(a => a.res_id)]]));
      }
      if(PartnersActivities.length > 0) {
       partners = await firstValueFrom(this.odooEm.search<Partner>(new Partner(), [['id', 'in', PartnersActivities.map(a => a.res_id)]]));
      }
      if(LeadsActivities.length > 0) {
       leads = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [['id', 'in', LeadsActivities.map(a => a.res_id)]]));
      }
      activities.forEach(a => {
        if (a.res_model === 'sale.order') {
          let saleOrder = saleOrders.find(so => so.id === a.res_id);
          a._ga_description = saleOrder.name + ' - ' + saleOrder.partner_id.name;
          if (saleOrder.opportunity_id.id) {
            a._ga_linkToObject = 'leads/'+saleOrder.opportunity_id.id+'/sale/'+saleOrder.id;
          }
          else {
            a._ga_linkToObject = '/immediate-sale/s/'+saleOrder.id;
          }
        } else if (a.res_model === 'res.partner') {
          let partner = partners.find(p => p.id === a.res_id);
          a._ga_description = partner.name;
          a._ga_linkToObject = '/contact/'+partner.id;
        } else if (a.res_model === 'crm.lead') {
          let lead = leads.find(l => l.id === a.res_id);
          a._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
          a._ga_linkToObject = '/leads/'+lead.id;
        }
      });
  }

  async loadNotificationDescription(notifications: MailNotification[]) {
    //here we take mail message body which is the description of the notification and is already usable.
    //we just need to return a link based on the res_model of the mail message connected to the notification


    let salesNotification = notifications.filter(n => n.mail_message_id.value?.model === 'sale.order');
    let partnerNotification = notifications.filter(n => n.mail_message_id.value?.model === 'res.partner');
    let leadNotification = notifications.filter(n => n.mail_message_id.value?.model === 'crm.lead');
    let purchaseNotification = notifications.filter(n => n.mail_message_id.value?.model === 'purchase.order');
    let pickingsNotification = notifications.filter(n => n.mail_message_id.value?.model === 'stock.picking');

    let saleOrders: SaleOrder[] = [];
    let partners: Partner[] = [];
    let leads: Lead[] = [];
    let purchases: PurchaseOrder[] = [];
    let pickings: StockPicking[] = [];

    //create arrays to search for the objects
    if (salesNotification.length > 0) {     
    saleOrders = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [['id', 'in', salesNotification.map(n => n.mail_message_id.value.res_id)]]));
    }
    if (partnerNotification.length > 0) {
    partners = await firstValueFrom(this.odooEm.search<Partner>(new Partner(), [['id', 'in', partnerNotification.map(n => n.mail_message_id.value.res_id)]]));
    }
    if (leadNotification.length > 0 ) { 
    leads = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [['id', 'in', leadNotification.map(n => n.mail_message_id.value.res_id)]]));
    }
    if (purchaseNotification.length > 0) {
    purchases = await firstValueFrom(this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [['id', 'in', purchaseNotification.map(n => n.mail_message_id.value.res_id)]]));
    }
    if (pickingsNotification.length > 0) {
    pickings = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), [['id', 'in', pickingsNotification.map(n => n.mail_message_id.value.res_id)]]));
    }

    //cicle through notifications to compile  _ga_linkToObject
    notifications.forEach(n => {
      if (n.mail_message_id.value?.model === 'sale.order') {
        let saleOrder = saleOrders.find(so => so.id === n.mail_message_id.value.res_id);
        n._ga_description = saleOrder.name + ' - ' + saleOrder.partner_id.name;
        if (saleOrder.opportunity_id.id) {
          n._ga_linkToObject = 'leads/'+saleOrder.opportunity_id.id+'/sale/'+saleOrder.id;          
        }
        else {
          n._ga_linkToObject = '/immediate-sale/s/'+saleOrder.id;
        }
      } else if (n.mail_message_id.value?.model === 'res.partner') {
        let partner = partners.find(p => p.id === n.mail_message_id.value.res_id);
        n._ga_description = partner.name;
        n._ga_linkToObject = '/contact/'+partner.id;
      } else if (n.mail_message_id.value?.model === 'crm.lead') {        
        let lead = leads.find(l => l.id === n.mail_message_id.value.res_id);
        n._ga_description = lead.tracking_code + ' ' + lead.partner_id.name;
        n._ga_linkToObject = '/leads/'+lead.id;
      } else if (n.mail_message_id.value?.model === 'purchase.order') {
        let purchase = purchases.find(p => p.id === n.mail_message_id.value.res_id);
        n._ga_description = purchase.name + ' - ' + purchase.partner_id.name;
        n._ga_linkToObject = 'https://o3.galimberti.eu/web#id='+purchase.id+'&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form';
      } else if (n.mail_message_id.value?.model === 'stock.picking') {
        let picking = pickings.find(p => p.id === n.mail_message_id.value.res_id);
        n._ga_description = picking.name + ' - ' + picking.partner_id.name;
        n._ga_linkToObject = '/pickings/internal/'+picking.id;
      }
    });
  }

  async openCalendar(){
    //redirect to https://o3.galimberti.eu/web#model=mail.activity&view_type=calendar&cids=1&menu_id=307 for now
    window.open('https://o3.galimberti.eu/web#model=mail.activity&view_type=calendar&cids=1&menu_id=307', '_blank');
  }

  async markAllAsRead() {
    if(confirm("Sei sicuro di voler segnare tutte le notifiche come lette?")) {
      this.loading = true;
      try {
        const unreadNotifications = this.notifications.filter(n => !n.is_read);
        for(let notification of unreadNotifications) {
          notification.is_read = true;
          await this.odooEm.update(notification, { is_read: true }).toPromise();
        }
        this.unreadNotificationsLength = 0;
        // this.emitDataToNavbar();
      } catch(error) {
        console.error('Error marking all as read:', error);
      } finally {
        this.loading = false;
      }
    }
  }
  
  setActiveTab(tab: 'activities' | 'notifications', event?: MouseEvent) {
    event?.stopPropagation();
    this.activeTab = tab;
  }

  async openNotification(n: MailNotification, event?: MouseEvent) {
    event?.stopPropagation();
    if (n._ga_linkToObject) {
      await window.open(n._ga_linkToObject, '_blank');
    }
    this.flagNotification(n);
    
  }

  openActivity(a: MailActivity, event?: MouseEvent) {
    event?.stopPropagation();  
    if (a._ga_linkToObject) {
      window.open(a._ga_linkToObject, '_blank');
    }
  }

  async flagNotification(n: MailNotification, event?: MouseEvent) {
    event?.stopPropagation();  // Add this
    n.is_read = !n.is_read;
   await this.odooEm.update(n, { is_read: n.is_read }).toPromise();
   this.unreadNotificationsLength = this.notifications.filter(n => !n.is_read).length;
  //  this.emitDataToNavbar();
  }


  async deleteActivity(a: MailActivity, event?: MouseEvent) {
    if (confirm("Sei sicuro di voler eliminare l'attività?")) {
      this.loading = true;
      await this.odooEm.delete(new MailActivity(), [a.id]).toPromise();
      await this.loadActivities();
      this.loading = false;
    }
    // this.emitDataToNavbar();
  }

  async completeActivity(a: MailActivity, event?: MouseEvent) {
    event?.stopPropagation();  // Add this
    if(confirm("Sei sicuro di voler completare l'attività?")) {
      this.loading = true;
      // call action_feedback method on the activity
      await this.odooEm.call2('mail.activity', 'action_feedback', [a.id]);
      await this.loadActivities();
      this.activitiesLength = this.activities.length;
      this.loading = false;
    }
    // this.emitDataToNavbar();
  }
}