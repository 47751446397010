import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MailActivity, MailActivityType } from '../models/mail.message';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../models/user.model';

// activity-editor.component.ts
@Component({
  selector: 'app-activity-editor',
  template: `
    <app-navbar [loading]="loading" backroute="../..">
      <a class="navbar-brand">
        Modifica attività
      </a>
    </app-navbar>
 @if (activity ){
    <div class="container-fluid p-4">
      <div class="card shadow-sm">
        <div class="card-body">
          <!-- Activity Type -->
          <div class="mb-3">
            <label class="form-label text-muted">Tipo attività</label>
            <select class="form-select" 
                    [(ngModel)]="activity.activity_type_id.id"
                    name="activity_type">
              <option *ngFor="let type of activityTypes" [value]="type.id">
                {{type.name}}
              </option>
            </select>
          </div>
 
          <!-- Title -->
          <div class="mb-3">
            <label class="form-label text-muted">Titolo</label>
            <input type="text" class="form-control" 
                   [(ngModel)]="activity.summary"
                   name="summary">
          </div>
 
          <!-- Notes -->
          <div class="mb-3">
            <label class="form-label text-muted">Note</label>
            <textarea class="form-control" rows="3"
                      [innerHTML]="activity.note "
                      name="note"></textarea>
          </div>
 
          <!-- Deadline -->
          <div class="mb-3">
            <label class="form-label text-muted">Scadenza</label>
            <input type="date" class="form-control"
                   [(ngModel)]="activity.date_deadline"
                   name="deadline">
          </div>
 
          <!-- Assigned User -->
          <div class="mb-3">
            <label class="form-label text-muted">Assegnato a</label>
            <select class="form-select" 
                    [(ngModel)]="activity.user_id.id"
                    name="user">
              <option *ngFor="let user of users" [value]="user.id">
                {{user.name}}
              </option>
            </select>
          </div>
 
          <div class="d-flex justify-content-end gap-2">
            <button type="button" class="btn btn-primary" (click)="save()">Salva</button>
          </div>
 
        </div>
      </div>
    </div>
}
  `
 })
 
 export class ActivityEditorComponent implements OnInit {
  activity: MailActivity;
  activityTypes: any[] = [];
  users: User[] = [];
  loading = false;
  
  constructor(
    private odooEm: OdooEntityManager,
    private router: Router,
    private route: ActivatedRoute
  ) {}
 
  async ngOnInit() {
    this.loading = true;
      this.route.params.subscribe(async (params) => {
        let id = params['aid'];
        let p = await firstValueFrom(this.odooEm.search<MailActivity>(new MailActivity(), [['id', '=', Number(id)]]));
        if (!p.length) return alert("Errore - l'attività non esiste" + id)
        this.activity = p[0];
  
    await this.loadUsers();
    await this.loadActivityTypes();
    console.log("EDITING ACTIVITY", this.activity);
    this.loading = false;
  });
}
 
  async loadUsers() {
    this.users = await firstValueFrom(
      this.odooEm.search<User>(new User(), [['active', '=', true]])
    );
  }
 
  async loadActivityTypes() {
    this.activityTypes = await firstValueFrom(
      this.odooEm.search<MailActivityType>(new MailActivityType(), [['active', '=', true]])
    );
  }
 
  async save() {
    this.loading = true;
    try {
      console.log("SAVING ACTIVITY with these new values: ", this.activity);
      await firstValueFrom(this.odooEm.update(this.activity, {
        activity_type_id: this.activity.activity_type_id.id,
        user_id: this.activity.user_id.id,
        summary: this.activity.summary,
        note: this.activity.note,
        date_deadline: this.activity.date_deadline
      }));
     
    } catch(error) {
      console.error('Error saving activity:', error);
    }
    this.loading = false;   
    this.router.navigate(['../..'], { relativeTo: this.route });
  }
 
 }