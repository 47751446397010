@if (packages.length > 0) {
    <div class="d-flex justify-content-center">
        <div class="dropdown w-100">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {{packages.length}} {{packages.length === 1 ? 'Collo' : 'Colli'}}
            </button>
            <ul class="dropdown-menu" [ngClass]="{'expanded': attachments}" style="transition: width 0.3s ease;">
                @for (pack of packages; track pack.id) {
                    <li>
                        <button 
                            class="dropdown-item d-flex justify-content-between align-items-center"
                            (click)="onClickPack(pack, $event)"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#photos-' + pack.id"
                            aria-expanded="false">
                            {{pack.name}} - {{pack._productQuantity}} {{product.uom_id.name}}

                            @if (pack.message_attachment_count > 0) {
                                <i class="fa fa-camera ms-2 text-primary"></i>
                            }
                        </button>
                        
                        @if (pack.message_attachment_count > 0) {
                            <div class="collapse px-3 py-2" [id]="'photos-' + pack.id">
                                @if (pack._attachments.length > 0) {
                                    <div class="row g-3">
                                        @for (photo of pack._attachments; track photo.id) {
                                            <div class="col-6">
                                                <div class="mb-2">
                                                    <img [src]="'https://o3.galimberti.eu/web/image/' + photo.id" 
                                                         class="img-thumbnail mb-1 w-100" 
                                                         style="height: 400px; object-fit: cover;"
                                                         [alt]="photo.name">
                                                    <div>
                                                        <a [href]="'https://o3.galimberti.eu/web/image/' + photo.id"
                                                           target="_blank" 
                                                           class="btn btn-sm btn-outline-primary w-100">
                                                            <i class="fa fa-external-link"></i> 
                                                            Apri
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </li>
                }
            </ul>
        </div>
    </div>
}