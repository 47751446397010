<div class="dropdown d-inline me-3" *ngIf="gmailTC">
    <button class="btn" type="button" id="gmaildd" data-toggle="dropdown" aria-expanded="false">
        <i class="fad fa-envelope text-primary" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> GMail
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item py-2" target="_blank"
                href="https://mail.google.com/mail/u/0/#inbox?compose=new&subject={{gmailTC}}">Invia Email</a></li>
        <li><a class="dropdown-item py-2" target="_blank"
                href="https://mail.google.com/mail/u/0/#search/{{gmailTC}}">Cerca su Gmail</a></li>
        <li><a class="dropdown-item py-2" target="_blank"
                href="https://groups.google.com/a/galimberti.eu/g/odoo/search?q={{gmailTC}}">Cerca in Gruppi</a></li>
    </ul>
</div>

<button *ngIf="!driveFolders || driveFolders.length == 0" class="btn " (click)="createFolder()">
    <i class="fa fa-folder text-muted  {{faSize}}"></i><br>
</button>

<a *ngIf="driveFolders && driveFolders.length > 0" target="_blank" class="btn "
    [href]="getDriveLinkFromId(driveFolders[0].value)">
    <i class="fa fa-folder text-primary {{faSize}}"></i><br>
</a>

<ng-container *ngIf="trelloBoardId">
    <a *ngFor="let c of trelloCards" class="btn text-center" href="https://trello.com/c/{{c.card_id}}" target="_blank">
        <i class="fab fa-trello text-primary  {{faSize}}"></i><br>
    </a>
</ng-container>

<!-- insert trello only if i'm not in preventivo (swapped with odoo crm) -->

<a *ngIf="trelloBoardId && (!trelloCards || trelloCards.length == 0) " class="btn btn-link text-center"
    (click)="openModal()" [disabled]="!driveFolders || driveFolders.length == 0" [hidden]="hideIcon" >
    <i [ngClass]="{'text-primary': trelloCards && trelloCards.length > 0, 'text-dark': trelloCards.length == 0 }"
        class="fab fa-trello  {{faSize}}"></i><br>

</a>

<!-- <button
    class="btn me-3 text-center" 
>
    <i class="fad fa-envelope text-primary" [ngClass]="customSize ? customSize : 'fa-3x'"></i><br> GMail
</button> -->

<div class="modal">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h3>Nuova card</h3>
                <button type="button" class="btn btn-link close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">



                <app-trello-insert *ngIf="!isPos && showTrelloInsert " [title]="cfg"
                    [trelloTemplateCard]="trelloTemplateCard" [trelloListId]="trelloListId"
                    [trelloBoardId]="trelloBoardId" [trelloDateLabel]="trelloDateLabel" (onInsert)="onInsert($event)">
                </app-trello-insert>

            </div>
        </div>
    </div>
</div>